import React, { useState, useEffect, lazy, createRef } from 'react';
import { getStaticPageTitle } from 'utils/Helpers';
import { useApi } from 'hooks/use-api/useApi';
import { useI18n } from 'utils/i18n/usei18n';
import { useUpdateState } from 'hooks/useUpdateState';
import { useSelector } from 'react-redux';
import { AppState } from 'store/index';
import { Constants } from 'utils/Constants';
import { ParentLinkType } from 'types/CmsTypes';
import RealTimeView from 'components/real-time-view/RealTimeView';

type PageProps = {
  type?: string;
  parent?: string;
  slug?: string;
  preview?: boolean;
};

const PreviewPage = lazy(() => import('pages/cms/PreviewPage'));
const ContentPage = lazy(() => import('pages/cms/ContentPage'));
const StartPage = lazy(() => import('pages/cms/StartPage'));
const LandingPage = lazy(() => import('pages/cms/landing-page/LandingPage'));
const ProfileLanding = lazy(() => import('pages/user-profile/profile-landing/ProfileLanding'));
const ProfilePage = lazy(() => import('pages/user-profile/profile/ProfilePage'));
const ProfileDataPage = lazy(() => import('pages/user-profile/profile-data/ProfileDataPage'));
const ProfileDeletePage = lazy(() => import('pages/user-profile/delete-profile/ProfileDeletePage'));
const ChangePasswordPage = lazy(() => import('pages/user-profile/change-password/ChangePasswordPage'));
const PlannerPage = lazy(() => import('pages/planner/PlannerPage'));
const LoginProceedPage = lazy(() => import('pages/login-proceed/LoginProceedPage'));
const DepartureJourneyPage = lazy(() => import('pages/journey/DepartureJourneyPage'));
const ReturnJourneyPage = lazy(() => import('pages/journey/ReturnJourneyPage'));
const ReviewPage = lazy(() => import('pages/review/ReviewPage'));
const PaymentPage = lazy(() => import('pages/payment/PaymentPage'));
const NetsRedirectPage = lazy(() => import('pages/payment-redirect/NetsRedirectPage'));
const VippsRedirectPage = lazy(() => import('pages/payment-redirect/VippsRedirectPage'));
const MyJourneysPage = lazy(() => import('pages/user-profile/my-journeys/MyJourneysPage'));
const MyCardsPage = lazy(() => import('pages/user-profile/my-cards/MyCardsPage'));
const ResponsePage = lazy(() => import('pages/response/ResponsePage'));

export const StaticRoutes = {
  [Constants.ROUTE_BASE]: () => <StartPage />,
  [Constants.ROUTE_PLANNER]: () => <PlannerPage />,
  [Constants.ROUTE_PROFILE_LANDING]: () => <ProfileLanding />,
  [Constants.ROUTE_JOURNEY]: () => <DepartureJourneyPage />,
  [Constants.ROUTE_RETURN_JOURNEY]: () => <ReturnJourneyPage />,
  [Constants.ROUTE_LOGIN_PROCEED]: () => <LoginProceedPage />,
  [Constants.ROUTE_REVIEW]: () => <ReviewPage />,
  [Constants.ROUTE_PAYMENT]: () => <PaymentPage />,
  [Constants.ROUTE_NETS_REDIRECT]: () => <NetsRedirectPage />,
  [Constants.ROUTE_VIPPS_REDIRECT]: () => <VippsRedirectPage />,
  [Constants.ROUTE_CREATE_PROFILE]: () => <ProfilePage />,
  [Constants.ROUTE_EDIT_PROFILE]: () => <ProfilePage />,
  [Constants.ROUTE_CHANGE_PASSWORD]: () => <ChangePasswordPage />,
  [Constants.ROUTE_PROFILE_DATA]: () => <ProfileDataPage />,
  [Constants.ROUTE_DELETE_PROFILE]: () => <ProfileDeletePage />,
  [Constants.ROUTE_MY_JOURNEYS]: () => <MyJourneysPage />,
  [Constants.ROUTE_MY_CARDS]: () => <MyCardsPage />,
  [Constants.ROUTE_RESPONSE_PARAM]: ({ type }: PageProps) => <ResponsePage type={type} />,
  [Constants.ROUTE_CMS_LEGAL_SLUG_OLD]: ({ slug }: PageProps) => <ContentPage slug={slug} />,
  [Constants.ROUTE_CMS_PREVIEW_SLUG]: ({ slug }: PageProps) => <PreviewPage slug={slug} />,
  ['/rtv']: () => <RealTimeView />, //TODO: Remove when Kent and Yen are done testing in prod.
};

const getLandingRoutes = (links: ParentLinkType[]) => {
  const updatedRoutes = Object.assign({}, StaticRoutes);
  links.forEach((element) => {
    updatedRoutes[`/${element.slug.current}`] = () => <LandingPage slug={element.slug.current} />;
  });
  return updatedRoutes;
};

const getRoutes = (links: ParentLinkType[]) => {
  const updatedRoutes = Object.assign({}, StaticRoutes);
  links.forEach((element) => {
    if (element.apiId === Constants.SANITY.GOA_PARTNER_PAGE) {
      updatedRoutes[`/${element.slug.current}`] = () => <ContentPage slug={element.slug.current} />;
    } else if (element.apiId === Constants.SANITY.GOA_ARTICLE_PAGE) {
      updatedRoutes[`/${element.slug.current}`] = () => <ContentPage slug={element.slug.current} />;
    } else if (element.apiId === Constants.SANITY.GOA_STANDARD_PAGE) {
      updatedRoutes[`/${element.slug.current}`] = () => <ContentPage slug={element.slug.current} />;
    }
    updatedRoutes[`/${element.slug.current}/:slug`] = ({ slug }) => <ContentPage slug={slug} />;
  });
  return updatedRoutes;
};

export const Routes = ({ children }) => {
  const { API_CALLS, requestCms } = useApi();
  const { translate } = useI18n();
  const { setTitle } = useUpdateState();
  const { pageTitle } = useSelector((store: AppState) => store.global);
  const onRouteChangeFocusRef = createRef<HTMLElement>();

  const [routesLoading, setRoutesLoading] = useState(true);
  const [cmsLinks, setCmsLinks] = useState([]);
  const [routes, setRoutes] = useState({});

  const fetchCmsLinks = async () => {
    try {
      const allInfoLinks = await requestCms(API_CALLS.CMS_GET_CMS_LINKS);
      const pageSlugs = await requestCms(API_CALLS.CMS_GET_PAGES_WITH_SLUGS);

      const header = allInfoLinks
        ? allInfoLinks.filter((link) => link.tag && link.tag === Constants.SANITY.GOA_INFORMATIONAL_TAG)
        : [];

      const footer = allInfoLinks
        ? allInfoLinks.filter((link) => link.tag && link.tag !== Constants.SANITY.GOA_INFORMATIONAL_TAG)
        : [];

      const articleLanding = allInfoLinks ? allInfoLinks.filter((link) => link.apiId) : [];

      const articlePage = pageSlugs
        ? pageSlugs.filter((item) => item.apiId && item.apiId === Constants.SANITY.GOA_ARTICLE_PAGE)
        : [];

      const standardPage = pageSlugs
        ? pageSlugs.filter((item) => item.apiId && item.apiId === Constants.SANITY.GOA_STANDARD_PAGE)
        : [];

      const partnerPage = pageSlugs
        ? pageSlugs.filter((item) => item.apiId && item.apiId === Constants.SANITY.GOA_PARTNER_PAGE)
        : [];

      setCmsLinks([...header, ...footer, ...articleLanding, ...partnerPage]);
      setRoutes(
        Object.assign(
          {},
          getLandingRoutes([...header, ...footer, ...articleLanding]),
          getRoutes([...header, ...footer, ...articleLanding, ...partnerPage, ...articlePage, ...standardPage]),
        ),
      );
      setRoutesLoading(false);
    } catch (error) {
      setCmsLinks([]);
      setRoutes(StaticRoutes);
      setRoutesLoading(false);
    }
  };

  useEffect(() => {
    //only handles title for predefined routes
    //dynamic routes from Cms will be set in respective component
    setTitle(getStaticPageTitle(window.location.pathname, translate));
  }, [window.location.pathname]);

  useEffect(() => {
    if (pageTitle) window.document.title = pageTitle;

    onRouteChangeFocusRef.current?.focus();
  }, [pageTitle]);

  useEffect(() => {
    (async () => {
      await fetchCmsLinks();
    })();
  }, []);

  return children({ routesLoading, routes, cmsLinks, onRouteChangeFocusRef });
};
