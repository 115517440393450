export const norwegian = {
  PASSENGER_TYPE_ADULT_DISPLAY: 'Voksen',
  PASSENGER_TYPE_ADULT_NAME: 'Voksen',
  PASSENGER_TYPE_ADULT_INFO: `Du er voksen om du er over 18 år
      og du ikke faller inn i en av kategoriene
      som gir deg rett til redusert pris,
      som eksempelvis Student eller Honnør.`,
  PASSENGER_TYPE_ADULT_RESTRICTIONS: [],
  PASSENGER_TYPE_INFANT_DISPLAY: 'Barn 0-5 år',
  PASSENGER_TYPE_INFANT_NAME: 'Barn 0-5',
  PASSENGER_TYPE_INFANT_INFO: `Barn 0-5 år gjelder alle under 6 år på avreisedagen.
      Barn i denne kategorien kan ikke reise alene, og personen i følget må være over 13 år.
      Generelt anbefaler vi at små barn reiser i følge med en som er voksen.
      Tilleggsvalg kan ikke knyttes til Barn 0-5 år.`,
  PASSENGER_TYPE_INFANT_RESTRICTIONS: [],
  PASSENGER_TYPE_SENIOR_DISPLAY: 'Honnør',
  PASSENGER_TYPE_SENIOR_NAME: 'Honnør',
  PASSENGER_TYPE_SENIOR_INFO: `Honnørkategorien gjelder deg som er over 67 år på avreisedagen,
      deg som mottar uførepensjon etter trygdeloven eller om du er blind, døv-blind eller svaksynt.
      Du kvalifiserer også til honnørbillett om du er ektefelle eller registret partner
      til en som oppfyller kravene ovenfor, og dere reiser ifølge.
      Husk at du må kunne dokumentere at du oppfyller kravene.
      Utover vanlig ID-kort for å dokumentere at man er over aldersgrensen,
      er gyldig dokumentasjon utstedt av NAV, Norges Blindeforbund eller
      Kompetansesenteret for døvblinde.`,
  PASSENGER_TYPE_SENIOR_RESTRICTIONS: [],
  PASSENGER_TYPE_ATTENDANT_DISPLAY: 'Reiser du med ledsager?',
  PASSENGER_TYPE_ATTENDANT_NAME: 'Ledsager',
  PASSENGER_TYPE_ATTENDANT_INFO: `Ledsagere til personer med
      funksjonsnedsettelse reiser gratis,
      dersom behovet kan dokumenteres. Gyldig dokumentasjon er:`,
  PASSENGER_TYPE_ATTENDANT_RESTRICTIONS: [
    'Nasjonalt ledsagerbevis',
    'Bevis fra Norges blindeforbund',
    'Bevis fra Kompetansesenter for døvblinde',
  ],
  PASSENGER_TYPE_MILITARY_DISPLAY: 'Vernepliktig',
  PASSENGER_TYPE_MILITARY_NAME: 'Vernepliktig',
  PASSENGER_TYPE_MILITARY_INFO:
    'Rabatten gjelder kun følgende situasjoner og personell, og ikke for ansatte i Forsvaret:',
  PASSENGER_TYPE_MILITARY_RESTRICTIONS: [
    'Reise til og fra sesjon',
    'Oppmøte til-, og dimisjon fra, førstegangstjeneste når du reiser i sivilt',
    'Soldater i førstegangstjeneste',
    'Oppmøte til befalsopptak',
    'Militære lærlinger i 1. og 2. utdanningsår',
    'Befalselever i sitt 1. utdanningsår',
    'Oppmøte til nytilføring i Heimevernet eller oppmøte til repetisjonstjeneste',
    'Vernepliktig akademiker den dagen de reiser til pliktig oppmøte',
  ],
  PASSENGER_TYPE_MILITARY_DOCUMENTATION: `Du må kunne dokumentere at du oppfyller kravene ved å
      fremvise innkallingsbrev (mil. Bl. 102) eller Forsvarets blå ID-kort.`,
  PASSENGER_TYPE_STUDENT_DISPLAY: 'Student',
  PASSENGER_TYPE_STUDENT_NAME: 'Student',
  PASSENGER_TYPE_STUDENT_INFO: `Om du er student kan du kjøpe studentbillett fra du er 18 år til dagen du fyller 30 år.
      Husk at du må fremvise skolebevis, studentbevis eller annen dokumentasjon utstedt av lærestedet, samt identitetskort
      eller pass. ISIC-kort er gyldig legitimasjon for internasjonale studenter.`,
  PASSENGER_TYPE_STUDENT_RESTRICTIONS: [],
  PASSENGER_TYPE_CHILD_DISPLAY: 'Barn 6-17 år',
  PASSENGER_TYPE_CHILD_NAME: 'Barn 6-17',
  PASSENGER_TYPE_CHILD_INFO: `Barn 6-17 år gjelder personer som er over 6 år og under 18 år på avreisedagen.
      Barn som faller i denne kategorien kan reise alene, selv om vi anbefaler at han eller hun er over 12 år.
      Husk at foresatte alltid er ansvarlig for barnet, selv når de ikke selv er til stede på toget.`,
  PASSENGER_TYPE_CHILD_RESTRICTIONS: [],
  PASSENGER_TYPE_BICYCLE_DISPLAY: 'Sykkel',
  PASSENGER_TYPE_BICYCLE_NAME: 'Sykkel',
  PASSENGER_TYPE_BICYCLE_DESC: 'Spesialbagasje',
  PASSENGER_TYPE_BICYCLE_INFO: `På Sørtoget Region kan man forhåndsbestille sykkelbillett,
    mens man på Sørtoget Lokal og Jærtoget må kjøpe dette ombord.
    Vær oppmerksom på at det er begrenset plass.
    Rullestoler og barnevogner vil prioriteres før sykkel.
    Denne kategorien kan også benyttes for spesialbagasje
    innenfor de begrensningene reglene setter.`,
  PASSENGER_TYPE_BICYCLE_RESTRICTIONS: [],
  PASSENGER_TYPE_SMALL_ANIMAL_DISPLAY: 'Lite dyr',
  PASSENGER_TYPE_SMALL_ANIMAL_NAME: 'Lite dyr',
  PASSENGER_TYPE_SMALL_ANIMAL_DESC: 'Under 40 cm',
  PASSENGER_TYPE_SMALL_ANIMAL_INFO: `Dyr som kan tas med på toget
    er hund og katt med skulderhøyde under 40 cm, gnagere i bur,
    burfugl og små skilpadder. Akvariefisk kan tas med om de er
    forsvarlig pakket. Andre dyr kan ikke tas med.
    Dyret må oppholde seg på gulvet foran de tildelte plassene,
    og kan ikke sitte i setet eller i midtgangen.
    Dyr kan ikke tas inn i kafé- og Ekstra-avdelingen eller Sovekupé.`,
  PASSENGER_TYPE_SMALL_ANIMAL_ARTICLE: 'ett',
  PASSENGER_TYPE_SMALL_ANIMAL_RESTRICTIONS: [],
  PASSENGER_TYPE_PUSH_CHAIR_DISPLAY: 'Barnevogn',
  PASSENGER_TYPE_PUSH_CHAIR_NAME: 'Barnevogn',
  PASSENGER_TYPE_PUSH_CHAIR_INFO: `På Sørtoget Region kan
    man forhåndsbestille plass til barnevogn,
    mens det ikke er mulig på Sørtoget Lokal og Jærtoget.
    Vær oppmerksom på at det er begrenset plass ombord.
    Rullestoler vil prioriteres før barnevogn.`,
  PASSENGER_TYPE_PUSH_CHAIR_ARTICLE: 'én',
  PASSENGER_TYPE_PUSH_CHAIR_RESTRICTIONS: [],
  PASSENGER_TYPE_ANIMAL_DISPLAY: 'Stor hund',
  PASSENGER_TYPE_ANIMAL_NAME: 'Stor Hund',
  PASSENGER_TYPE_ANIMAL_DESC: 'Over 40 cm',
  PASSENGER_TYPE_ANIMAL_INFO: `Hund med skulderhøyde over 40 cm
    tildeles et eget sete å sitte foran.
    Hunden kan ikke oppholde seg i setet eller i midtgangen.
    Hunden kan ikke tas inn i kafé- og Ekstra-avdelingen eller Sovekupé.`,
  PASSENGER_TYPE_ANIMAL_ARTICLE: 'én',
  PASSENGER_TYPE_ANIMAL_RESTRICTIONS: [],
  PASSENGER_TYPE_SERVICEDOG_DISPLAY: 'Er hunden i tjenste?',
  PASSENGER_TYPE_SERVICEDOG_NAME: 'Servicehund',
  PASSENGER_TYPE_SERVICEDOG_INFO: `Hunder i tjeneste, det vil si fører-,
    service- eller redningshunder, reiser gratis og kan tas med i alle deler
    av toget med unntak av dyrefri vogn. En forutsetning for dette er
    at fører- eller servicehund er utstyrt med vest og/eller sele med håndtak,
    mens redningshunder skal ha på merketrekant. Gyldig dokumentasjon må kunne fremvises.`,
  PASSENGER_TYPE_SERVICEDOG_RESTRICTIONS: [],
  TO_MANY_PASSENGERS_ERROR_FIRST: `For gruppebilletter, det vil si 10 passasjerer eller mer, må du kontakte vårt kundesenter ved å benytte `,
  MINIMUM_ONE_PASSENGER_ERROR: 'Du må velge minst én reisende som ikke er et barn under 6 år',
  TO_MANY_PASSENGERS_ERROR_SECOND: ` kontaktskjema her.`,
  INFANT_ONLY_PASSENGER_ERROR: `Barn kan ikke reise uten følge.`,
  INFANT_TRAVELLING_WITH_CHILD_WARNING: `Vær oppmerksom på at små barn ikke kan reise med barn under 13 år som eneste følge.`,
  CHILD_TRAVELLING_ALONE_WARNING: `Selv om barn i denne kategorien kan reise alene, anbefaler vi at barnet er over 12 år.`,
  INFANT_SUPPLEMENT_ERROR: 'Tilleggsvalg kan ikke legges til Barn 0-5 år',
  MAX_SUPPLEMENT_ERROR: 'Du kan kun legge til ett tilleggsvalg per reisende. Sykkel kommer i tillegg.',
  MAX_PASSENGER_BICYCLE_ERROR: 'Du kan kun legge til én sykkel per reisende',
  MAX_BICYCLE_ERROR: 'Det er plass til maksimalt 5 sykler på togene våre',
  CHOOSE_DEPARTURE: 'Velg avgang',
  SELECT_RETURN_TICKET: 'Velg retur',
  REVIEW_TICKET: 'Kontroller billetten',
  OK: 'OK',
  CONTINUE: 'Fortsette',
  PAY: 'Betale',
  AMOUNT_PAYED: 'Kjøpssum',
  PAYMENT: 'Betaling',
  TRACK: 'Spor',
  JOURNEY_TIME: 'Reisetid',
  JOURNEY: 'Reise',
  JOURNEYS: 'Reiser',
  JOURNEY_DEPARTURE: 'Utreise',
  JOURNEY_RETURN: 'Retur',
  PASSENGERS: 'Reisende',
  SUPPLEMENTS: 'tilleggsvalg',
  TRAVEL_DETAILS: 'Reisedetaljer',
  TICKETS: 'Billetter',
  PRICE_FROM: 'fra kr.',
  PRICE: 'Pris',
  PRICE_STARTING_FROM: 'Pris fra kr.',
  TAX_OF: 'herav mva.',
  CHANGES: 'bytter',
  CHANGE_SEARCH: 'Endre søk',
  CHANGE_DEPARTURE: 'Endre utreise',
  CHANGE_RETURN: 'Endre retur',
  CANCEL: 'Avbryt',
  NO_DASH_START_OR_END: 'Bindestreken må ligge mellom to navn.',
  TICKET_RECIVE_METHOD_TEXT: 'Hvordan ønsker du å motta billetten?',
  CONTACT_INFO: 'Kontaktinformation',
  EMAIL_ADRESS: 'E-post',
  PHONENUMBER: 'Telefonnummer',
  SHOW_MORE_JOURNEYS: 'Vis fler reiser',
  NO_MORE_JOURNEYS: 'Fant ingen fler reiser',
  NO_TICKETS_FOUND: 'Vi fant ingen billetter knyttet til profilen din',
  NO_TICKETS_FOUND_FOR_USER:
    'Har du kjøpt en billett som burde vært vist her? Prøv igjen senere eller kontakt kundeservice så kan de hjelpe deg',
  MUST_BE_ATLEAST_TWO: 'Navnet må bestå av minst 2 bokstaver',
  BENEFIT_PROGRAM_TEXT_UPPER:
    'Om du oppretter profil hos oss slipper du å fylle inn de samme opplysningene hver gang du kjøper billetter hos oss. Vi vil ta vare på dem for deg. Ønsker du å lese mer om hvordan vi behandler persondataene dine, kan du lese om det i vår ',
  BENEFIT_PROGRAM_TEXT_LOWER:
    'Du kan fritt opprette profil så lenge du er over 16 år. Personer under 16 år kan opprette profil etter samtykke fra foresatt. Kontakt kunderservice for å gi samtykke på e-postadresse:',
  CONTACT_INFO_WARNING:
    'Hovedreisende er den som har ansvar for billettene under reisen. Navn og kontaktopplysninger benyttes kun ved utstedelse av billetter og for reiseinformasjon til de bestilte avgangene.',
  BUS_STATION_STOP: 'Stopp',
  PAYMENT_METHOD_TEXT: 'Velg hvordan du ønsker å betale',
  ACCEPT_TERMS_AND_CONDITION_TEXT: 'Jeg har lest og akseptert Go-Aheads',
  TERMS: 'Vilkår',
  TRANSPORT_CONDITIONS: 'Transportvilkår',
  PERSONAL_INTEGRITY_DECLARATION: 'Personvernerklæring',
  PURCHASE_FAILED: 'Vi klarte dessverre ikke å fullføre din betaling',
  PURCHASE_VIPPS_FAILED:
    'Din betaling med VIPPS ble ikke gjennomført. Vennligst prøv igjen eller kontakt kundeservice.',
  PURCHASE_FAILED_DESCRIPTION:
    'Vi klarte ikke å gjennomføre kjøpet. Om du ikke har avbrutt betalingen selv, ta gjerne kontakt med kundesenteret vårt, så hjelper de deg med å kjøpe billettene du trenger.',
  SEND_PDF_FAILED:
    'Kjøpet er fullført, vi har ikke klart å sende alle reisedokumentene til deg. Vi håper du kan se om din epostadresse er riktig, ${email}. Du kan prøve å sende den på nytt, ved å trykke på knappen under. Eller kontakte kundeservice for mer hjelp.',
  SEND_SMS_FAILED:
    'Vi har mottatt betalingen og kjøpet er fullført, men SMS ble av en eller annen grunn ikke sendt ut slik den burde. Vær vennlig å kontakte kundeservice og oppgi referansenummeret under, så vil de hjelpe deg.',
  OPENING_TIMES: 'Åpningstider',
  MONDAY_TO_FRIDAY_OPENING_TIME: 'Mandag-fredag: kl. 07:00-23:00.',
  SATURDAY_OPENING_TIME: 'Lørdag: kl. 08:00-21:00.',
  SUNDAY_OPENING_TIME: 'Søndag: kl. 09:00-23:00.',
  AND: 'og',
  TICKET: 'Billett',
  PAYMENT_COMPLETE_TEXT: 'Vi har mottatt betalingen ',
  PAYMENT_COMPLETE_BUT_CANT_SEND_TEXT: 'Kjøpet er fullført, men billettene ble ikke sendt',
  PAYMENT_COMPLETE_BUT_CANT_SEND_SMS: 'Kjøpet er fullført, men SMS ble ikke sendt',
  COMPLETE_PDF_TEXT1: 'Betalingen er mottatt, og du vil straks motta en e-post med billetter og kvittering.',
  COMPLETE_PDF_TEXT2: 'Om du ikke skulle motta e-posten innen rimelig tid, ta kontakt med kundeservice.',
  COMPLETE_ON_STATION_TEXT1:
    ' Betalingen er mottatt, og du kan hente billetten din i toget, billettautomaten eller i bemannede servicepunkter ved å oppgi denne hentekoden:',
  COMPLETE_ON_STATION_TEXT2:
    ' Du vil straks motta e-post med hentekode og kvittering. Om du ikke skulle motta e-posten innen rimelig tid, ta kontakt med kundeservice.',
  HIDE_DETAILS: 'Se detaljer',
  SHOW_DETAILS: 'Skjul detaljer',
  TRAVEL_WHERE: 'Hvor vil du reise?',
  FROM: 'Fra',
  TO: 'Til',
  FETCHING_YOUR_POSITION: 'Henter din posisjon',
  SWAP_STATIONS: 'Bytt stasjoner',
  NO_HIT: 'Ingen treff',
  SEE_ALL_STATIONS: 'Se alle stasjoner',
  DELETE: 'Ta bort',
  EDIT: 'Endre',
  FIRST_NAME: 'Fornavn',
  LAST_NAME: 'Etternavn',
  CREATE_PROFILE: 'Opprett profil',
  NEW_USER: 'Ny bruker',
  NORWAY: 'Norge',
  JANUARY: 'Januar',
  FEBRUARY: 'Februar',
  MARS: 'Mars',
  APRIL: 'April',
  MAY: 'Mai',
  JUNE: 'Juni',
  JULY: 'Juli',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'Oktober',
  NOVEMBER: 'November',
  DECEMBER: 'Desember',
  ALL: 'Alle',
  FUTURE: 'Kommende',
  PAST: 'Fullførte',
  CREATED: 'Opprettet',
  DATE_OF_PURCHASE: 'Kjøpsdato',
  DATE_OF_TRAVEL: 'Reisedato',
  CALCULATED_BONUS: 'Utregnet bonus',
  VERIFY_ACCOUNT_CUSTOMER_INFO: 'Bekreft kontoen din på kundeinformasjon',
  GIVE_PHONENUMBER: 'Tast inn telefonnummer',
  GIVE_YOUR_PHONENUMER: 'Tast inn ditt telefonnummer',
  PHONE_NUMBER_MODAL_TEXT:
    'Du må oppgi telefonnummeret ditt for å motta billettkoden på SMS. Vi lagrer ikke telefonnummeret ditt i profilen din.',
  LOYALTY_PROGRAM_LABEL: 'Jeg ønsker å bli med i fordelsprogrammet',
  LOYALTY_PROGRAM_HEADER: 'Bli med i Go-Aheads fordelsprogram',
  LOYALTY_PROGRAM_DESCRIPTION:
    'Her kan du opprette profil og melde deg inn i Go-Aheads fordelsprogram. Som medlem som har kjøpt billett via nettstedet vårt, sørger vi for at du automatisk får erstatning dersom vi ikke skulle klare å opprettholde reisegarantien vår. Du trenger ikke å foreta deg noe - pengene blir returnert direkte deg!',
  LOYALTY_PROGRAM_HEADER_LOGGED_IN: 'Medlem i Go-Aheads fordelsprogram',
  LOYALTY_PROGRAM_DESCRIPTION_LOGGED_IN:
    'Med fordelsprogrammet vårt vil vi sørge for at du vil få erstatningen du har krav på helt uten at du trenger å gjøre noe. Pengene vil automatisk føres tilbake til kortet du betalte med.',
  REFUND_LINK: 'Les mer om erstatning ved forsinkelse',
  JOURNEY_NOT_REFUNDABLE: 'Reisen kan ikke refunderes.',
  CUSTOMER_SERVICE: 'Kundeservice',
  SHOW_ADDONS: 'Vis tilleggsvalg',
  HIDE_ADDONS: 'Skjul tilleggsvalg',
  PHONE: 'Telefon',
  GO_ALIVE_WEB: 'Go-Alive (Web)',
  SEND_RECEIPT_EMAIL: 'Send kvittering på e-post',
  RECEIPT_SENT: 'Kvitteringen er sendt',
  EMAIL: 'E-post',
  CANT_BE_EMPTY: 'Kan ikke være tom',
  PHONE_NUMBER_RESTRICTIONS: 'Kun tall er tillatt',
  COUNTRY_CODE_ERROR: 'Må være mellom 1 og 4 tall',
  MUST_BE_BETWEEN: 'Må være mellom',
  ONLY_LETTERS_ERROR: 'Kan kun bestå av bokstaver',
  NOT_VALID_BIRTHDAY: 'Ugyldig fødselsdato',
  NUMBERS: 'tall',
  MUST_BE_FOUR: 'Må være 4 siffer',
  NOT_VALID_DAY: 'Ikke gyldig dag',
  NORWEGIAN: 'norsk',
  NORWEGIAN_PHONE: 'Telefonnummer (norsk)',
  ONLY: 'Kun',
  GENERAL_ERROR_HEADLINE: 'Noe gikk galt!',
  TECHNICAL_ERROR_TEXT: 'Vi har for øyeblikket tekniske problemer. Vennligst prøv igjen!',
  GENERAL_ERROR_TEXT:
    'Vi har for øyeblikket tekniske problemer. Vennligst prøv igjen eller kontakt kundeservice for å gjennomføre din bestilling.',
  FETCH_JOURNEYS_ERROR:
    'Vi har for øyeblikket tekniske problemer. Vennligst kontakt kundeservice for å gjennomføre din bestilling. Tlf: +47 61 25 80 00',
  NOT_AVAILABLE: 'Ikke tilgjengelig',
  SOLD_OUT: 'Utsolgt',
  JOURNEY_IS_CANCELLED: 'Reisen har blitt kansellert',
  TRAIN_HAS_DEPARTED: 'Dette toget har forlatt stasjonen',
  TRAIN_LEFT_AT_PAST: 'Gikk fra',
  TRAIN_LEFT_AT_FUTURE: 'Går fra',
  NOT_AVAILABLE_CHOICE_ERROR: 'Ikke tilgjengelig for dine valg',
  TICKET_PDF_TEXT: 'Print eller lagre som PDF',
  TICKET_ON_TRAIN_TEXT: 'Ombord i toget',
  TICKET_ON_STATION_TEXT: 'Hente billett',
  TICKET_PDF_SUBTEXT:
    'Skriv ut billetten, eller last ned til mobil, nettbrett eller datamaskin som du kan ta med på toget.',
  TICKET_ON_TRAIN_SUBTEXT:
    'Du vil få tilsendt et referansenummer for å hente ut billetten på toget. Finn plassen din i toget, så kommer konduktøren med billetten din under billettkontrollen. Dette gjelder kun på Sørtoget.',
  TICKET_ON_STATION_SUBTEXT:
    'Du vil få tilsendt en hentekode som du kan benytte til å hente ut billetten på automat eller i skranken på betjente stasjoner. Vær oppmerksom på at ikke alle stasjoner har  billettautomat.',
  DELAY: 'Forsinkelse',
  DELAY_PAST_TENSE: 'forsinket',
  MUST_HAVE_NORWEGIAN_PHONE_SHORT: 'Må være et gyldig norsk nummer',
  MUST_HAVE_PHONE_VIPPS: 'Forutsetter norsk telefonnummer',
  NORWEGIAN_NUMBER_REQIRED: 'Norsk nummer (obligatorisk)',
  ADD_PHONE_NUMBER: 'Legg inn telefonnummer',
  MAX_ERROR: 'kan ikke ha mer enn',
  CHARACTERS: 'tegn',
  DAYS: 'dager',
  SELECTABLE_DAYS_INFO:
    'Vi setter opp togruter ca 90 dager frem i tid, datoer senere enn det er derfor ikke tilgjengelig.',
  HENTE_CODE: 'Hentekode',
  REFERENCE_NUMBER: 'Referansenummer',
  REFERENCE: 'Referanse',
  TRANSFER_TIME: 'Overgangstid',
  TRANSFER_GET_ERROR_HEADLINE: 'Vi finner ingen avganger',
  TRANSFER_GET_ERROR_TEXT:
    'Noe gikk dessverre galt da vi prøvde å hente de alternative avgangene, vennligst prøv igjen senere eller kontakt kundeservice.',
  TRANSFER_REPLACE_ERROR_HEADLINE: 'Vi kan ikke endre din avgang',
  TRANSFER_REPLACE_ERROR_TEXT:
    'På grunn av ukjent feil kunne vi dessverre ikke endre avgang for reisen, vennligst prøv igjen senere eller kontakt kundeservice.',
  EMAIL_ADRESS_VALIDATION_TEXT: 'Du må skrive inn en gyldig e-postadresse',
  NO_JOURNEYS_FOUND: 'Ingen avganger funnet',
  ALTERNATIVE_JOURNEYS: 'Alternative avganger',
  JOURNEY_LEAVE: 'Avgang',
  TICKET_NOT_AVAILABLE: 'Denne billetten er dessverre ikke tilgjengelig lenger',
  PAYMENT_METHODS_AND_PAYMENT: 'Fullfør kjøp',
  ALTERNATIV_TRANSPORT: 'Alternativ transport',
  RETURN_JOURNEY_WARNING: 'Denne reisen starter før du har kommet frem til',
  ALTERNATIVE_PAYMENT_OPTIONS: 'Vis andre betalingsmåter',
  SEAT: 'Sete',
  NO_SEAT_RESERVATION: 'Ingen setereservasjon',
  WAGON: 'Vogn',
  BACK_TO_MAIN_PAGE: 'Tilbake til forsiden',
  BACK: 'Tilbake',
  CREATE_ACCOUNT: 'Opprett profil',
  SHOW_PRICE_DETAILS: 'Vis prisdetaljer',
  HIDE_PRICE_DETAILS: 'Skjul prisdetaljer',
  REMEMBER_ME: 'Husk meg',
  PASSWORD: 'Passord',
  NO_ACCOUNT_YET: 'Har du ikke profil ennå?',
  CREATE_PROFIL: 'Opprett profil',
  SHOW_PASSWORD: 'Vis passord',
  HIDE_PASSWORD: 'Skjul passord',
  FORGOT_PASSWORD: 'Glemt passord?',
  LOG_IN: 'Logg inn',
  MY_PAGES: 'Mine sider',
  CREATE_PROFILE_SUCCESS_HEADING: 'Profilen er opprettet',
  CREATE_PROFILE_SUCCESS_TEXT: 'Takk for at du opprettet profil hos oss. Vi ønsker deg en fin reise!',
  COMPLETE_SEARCH_ABOVE: 'Vennligst fullfør søket ovenfor',
  NON_SELECTED: 'Ikke valgt',
  INVAILD_DEPATURE_STATION: 'Ugyldig navn på avreisestasjon.',
  VALID: 'Gyldig',
  CURRENT_PASSWORD: 'Nåværende passord',
  NEW_PASSWORD: 'Nytt passord',
  YOUR_NEW_PASSWORD: 'Ditt nye passord',
  REPEAT_NEW_PASSWORD: 'Gjenta nytt passord',
  REPEAT_PASSWORD: 'Gjenta passord',
  EDIT_PROFILE_SUCCESS_PAGE_MSG: 'Endringene har blitt lagret.',
  CREATE_PROFILE_INFO:
    'Du kan fritt opprette profil så lenge du er over 16 år. Personer under 16 år kan opprette profil etter samtykke fra foresatt. Kontakt kundeservice for å gi samtykke på e-postadresse: ',
  CREATE_PROFILE_ERROR_HEADING: 'Profilen din ble ikke opprettet',
  CREATE_PROFILE_ERROR_TEXT:
    'Noe har gått galt, og vi har ikke klart å opprette en profil for deg. Det kan skyldes at det allerede finnes en profil knyttet til denne e-postadressen. Forsøk å logge inn eller opprette profilen igjen ved å trykke på en av knappene under.',
  CREATE_PROFILE_SMS_INFO:
    'SMS-løsningen vår er dessverre ikke helt klar til bruk enda, men kryss gjerne av om du ønsker at vi kontakter deg via SMS i fremtiden.',
  BIRTHDATE: 'Fødselsdato',
  DAY: 'Dag',
  MONTH: 'Måned',
  YEAR: 'År',
  COUNTRY: 'Land',
  COUNTRY_CODE: 'Landkod',
  POSTCODE: 'Postnummer',
  THROUGH_EMAIL: 'På e-post',
  THROUGH_SMS: 'På SMS',
  PAYMENT_CARD: 'Betalingskort',
  ARRIVED: 'Ankom',
  CLOCK: 'klokken',
  CLOCK_SHORT: 'kl',
  PAYMENT_CARD_INFO1:
    'Vi anbefaler å lagre betalingskortet ditt, slik at du slipper å oppgi betalingsinformasjon hver gang du kjøpe billetter. Opplysningene bevares på sikker måte av Nets.',
  PAYMENT_CARD_INFO2:
    'Du kan velge å lagre betalingskortene direkte i appen, men vær oppmerksom på at opplysningene da vil slettes ved eventuell avinstallering eller om du skifter telefon. Lagrer du i stedet kortet på Min side på nettstedet vil det bevares for bruk både på nettstedet og på mobilappen.',
  CARD_STORED_AT: 'Kort lagret hos vår betalingsleverandør',
  CARDS_EMPTY_MESSAGE:
    'Du har ikke lagt til noe betalingskort. Klikk på legg til kort knappen for å legge til et betalingskort.',
  ADD_CARD: 'Legg til kort',
  DELETE_CARD: 'Slette kort',
  SAVE_CARD: 'Lagre kort',
  NICKNAME: 'Kallenavn',
  CARD_NICKNAME_OPTIONAL: 'Kallenavn (valgfri)',
  SET_CARD_PRIMARY: 'Sett som hovedkort',
  PRIMARY: 'Hovedkort',
  CHANGE_OTHER_CARD_MSG: 'Du må velge et annet kort som hovedkort før de sletter dette kortet',
  CARD_DELETE_MESSAGE: 'Er du sikker på at du ønsker å slette dette kortet?',
  CARD_FETCH_ERROR_HEADLINE: 'Kan ikke hente kort',
  CARD_FETCH_ERROR_TEXT:
    'Dessverre kunne vi ikke hente kortene dine, vennligst prøv på nytt eller kontakt kundeservice.',
  CARD_ADD_ERROR_HEADLINE: 'Kan ikke legge til kort',
  CARD_ADD_ERROR_TEXT:
    'Vi klarte dessverre ikke å legge til kortet ditt, vennligst prøv igjen eller kontakt kundeservice.',
  CARD_ADD_SUCCESS_TEXT: 'Vi har lagt til kortet ditt',
  CARD_UPDATE_ERROR_HEADLINE: 'Kan ikke oppdatere kortet',
  CARD_UPDATE_ERROR_TEXT:
    'Vi klarte dessverre ikke å oppdatere kortet ditt, vennligst prøv igjen eller kontakt kundeservice.',
  CARD_UPDATE_SUCCESS_TEXT: 'Vi har oppdatert kortet ditt',
  CARD_DELETE_ERROR_HEADLINE: 'Kan ikke slette kortet',
  CARD_DELETE_ERROR_TEXT:
    'Vi klarte dessverre ikke å slette kortet ditt, vennligst prøv igjen eller kontakt kundeservice.',
  CARD_DELETE_SUCCESS_TEXT: 'Vi har slettet kortet ditt',
  INFO_ABOUT_JOURNEYS: 'Informasjon om mine reiser, billetter eller betalingskort',
  INFO_ABOUT_MY_JOURNEYS: 'Informasjon om mine reiser, billetter...',
  MY_JOURNEYS: 'Mine reiser',
  MY_JOURNEYS_PARAGRAPH:
    'Billettene i denne oversikten er ikke gyldige som reisedokumenter. Trenger du hjelp til å skrive ut en ny billett eller har kjøpt en billett som burde vært vist her, kan du ta kontakt med kundeservice så vil de hjelpe deg.',
  MY_JOURNEYS_NO_TICKETS_TITLE: 'Her vil du finne en oversikt over billettkjøpene dine',
  MY_JOURNEYS_NO_TICKETS_PARAGRAPH:
    'Her vil du finne en oversikt over billettkjøpene dine. Foreløpig ser det ikke ut til at vi finner noen billetter knyttet til profilen din. ',
  INFO_ABOUT_OFFERS: 'Gode tilbud og tips skreddersydd for meg',
  ON_STATION_TICKETS: 'Hentekode for billetter',
  ACCEPT_CREATE_PROFILE: 'Jeg forstår og vil opprette konto',
  OPTIONAL_CHOICE: 'Valgfri',
  MANDATORY: 'Dette feltet må fylles ut',
  MANDATORY_CHOICE: 'Obligatorisk, kan ikke endres',
  MANDATORY_FIELD: 'Obligatorisk',
  MANDATORY_CHOOSE_OPTION: 'Du må velge et alternativ',
  MANDATORY_STATION: 'Du må velge en stasjon',
  CHOOSE_OPTION: 'Velg et alternativ',
  COMMUNICATIONS_SETTINGS: 'Kommunikasjonsinnstillinger',
  WHEN_CREATING_PROFILE: 'Når du oppretter profil hos oss',
  RECEIPTS: 'Kvitteringer',
  TICKET_BOUGHT_FROM: 'Billettert av',
  MAX_CHAR_ERROR_80: 'Kan ikke ha mer enn 80 tegn',
  MAX_CHAR_ERROR_50: 'Kan ikke ha mer enn 50 tegn',
  MAX_NUMBERS_ERROR_2: 'Max 2 tall',
  ONLY_NUMBERS_ERROR: 'Kun tall',
  ACCEPT_PROFILE_CONDITIONS:
    'vil vi ta vare på opplysningene som du oppga ovenfor. Om du ønsker å lese mer om hvordan vi behandler persondataene dine, kan du lese om det i vår',
  CONTINUE_WITHOUT_ACCOUNT: 'Fortsett uten konto',
  CLIENT_INFORMATION: 'Kundeinformasjon',
  CLIENT_DATA: 'Mine Data',
  HANDLING_CLIENT_DATA: 'Håndtering av personvernet ditt',
  LOGOUT: 'Logg ut',
  WRONG_CREDENTIALS: 'Feil brukernavn eller passord',
  CHANGE_TO_NEW_PASSWORD: 'Bytt passord',
  FORGOT_PASSWORD_INFORMATION:
    'Har du glemt passordet ditt? Skriv inn e-postadressen din så får du tilsendt instruksjoner for endring av passord.',
  CHANGE_PASSWORD: 'Endre passord',
  FORGOT_PASSWORD_SUCCESS:
    'Vi har sendt deg en e-post med nytt passord. Etter at du har logget inn bør du endre til et selvvalgt passord i profilen din. Vi gjør oppmerksom på dersom vi ikke skulle ha registert en profil knyttet til denne e-postadressen, vil vi av sikkerhetsmessige årsaker ikke sende deg noen melding.',
  CUSTOMER_INFORMATION: 'Kundeinformasjon',
  CUSTOMER_INFORMATION_SUM: 'Persondata, Kommunikasjonsinnst...',
  PROFILE_DATA_HEADLINE: 'Innsikt i og sletting av data',
  PROFILE_DATA_PARAGRAPH:
    ' Du har rett til å vite hva vi vet om deg, og kan be om innsyn. Du kan også be om at vi sletter profilen og alle de persondataene som vi samlet inn. Hvis du ønsker å slette profilen din hos oss så kan du trykke på "Slette profil" knappen nederst på siden. For hjelp til innsyn, ta kontakt med kundeservice:',
  PROFILE_DATA_COMPLAIN:
    'Husk at om du skulle være misfornøyd med hvordan vi skulle ha håndtert personopplysningene dine, så er det Datatilsynets jobb å sørge for at regelverket blir fulgt og du har rett til å klage til dem.',
  DELETE_PROFILE: 'Slette profil',
  DELETE_MY_PROFILE: 'Slett profilen min',
  PROFILE_DELETE_INFO_UPPER:
    'Ved sletting, som normalt skjer innen 3 dager, vil du automatisk logges ut av nettstedet og appen, og du vil ikke kunne logge på uten å opprette en ny profil. Om du har lastet ned en app på telefonen din, må du selv slette denne.',
  PROFILE_DELETE_INFO_LOWER:
    'Enkelte data vil ikke kunne slettes fordi bokføringsloven pålegger oss å ta vare på dem. Disse vil følge ordinære rutiner for sletting.',
  PROFILE_DELETE_CONSENT_HEADLINE: 'Jeg godkjenner sletting av data',
  PROFILE_DELETE_CONSENT_TEXT: ' Jeg er innforstått med at ved å trykke på Slett profilen min, så aksepterer jeg at:',
  PROFILE_DELETE_CONSENT_BOX_ONE:
    'Jeg vil ikke lenger vil ha tilgang til profilen, og tjenestene knyttet til den. Det gjelder både nettstedet og mobilappen.',
  PROFILE_DELETE_CONSENT_BOX_TWO:
    'Jeg vil miste tilgang til alle billetter som er aktive nå eller jeg har kjøpt med avgang frem i tid.',
  PROFILE_DELETE_CONSENT_BOX_THREE:
    'Jeg kan ikke angre. Slettingen er endelig og alle data vil være borte for alltid. Om jeg ønsker en ny profil må jeg lage en ny.',
  PROFILE_DELETE_SUCCESS_HEADLINE: 'Profilen din blir nå slettet',
  PROFILE_DELETE_SUCCESS_TEXT:
    'Du er nå automatisk logget ut fra nettstedet og alle data knyttet til profilen din blir slettet. Dette skjer normalt innen 3 dager. Om du har en mobil app vil du logges ut automatisk, men du må selv slette appen fra telefonen din.',
  PROFILE_DELETE_ERROR_TEXT:
    'Det kan se ut til at noe gikk galt under slettingen av profilen din. Prøv igjen eller kontakt kundeservice.',
  PROFILE_DELETE_ERROR_MESSAGE: 'Du må akseptere betingelsene før du kan slette profilen din',
  PERSONAL_INTEGRITY_BTN: 'Personvern i Go-Ahead',
  ADDRESS: 'Adresse',
  CHANGE_PASSWORD_ERROR_HEADING: 'Vi klarte ikke å opprette nytt passord',
  CHANGE_PASSWORD_ERROR_TEXT:
    'Noe har gått galt, og vi har ikke klart å opprette et nytt passord for deg. Trykk på knappen under for å forsøke igjen.',
  SAVE_CHANGES_TECHNICAL_ERROR:
    'Det oppstod en teknisk feil som gjør at vi ikke klarte å gjennomføre endringen. Vennligst prøv igjen.',
  CREATE_PROFILE_PASSWORD_ERROR: '5-100 tegn, inkludert tall og store og små bokstaver',
  CHANGE_PASSWORD_ERROR_BUTTON_TEXT: 'Bytt passord',
  CHANGE_PASSWORD_SUCCESS_HEADING: 'Passordet er endret',
  CHANGE_PASSWORD_INVALID_PASSWORD_TEXT: 'Dette passordet følger ikke reglene over',
  CHANGE_PASSWORD_NEW_OLD_SAME: 'Nytt og gammelt må ikke være like',
  CHANGE_PASSWORD_NOT_EQUAL: 'De to passordene er ikke like',
  CHANGE_PASSWORD_INFO_HEADLINE: 'Husk på sikkerheten! Opprett et sikkert passord ved å:',
  CHANGE_PASSWORD_INFO_LENGTH: 'ha en lengde på 5-100 tegn',
  CHANGE_PASSWORD_INFO_CHARACTERS: 'bruk store og små bokstaver',
  CHANGE_PASSWORD_INFO_NUMBERS: 'bruk tall',
  CHANGE_PASSWORD_INFO_GENERAL: 'ikke bruk ord som kan knyttes til deg selv eller til denne tjenesten',
  SAVE_CHANGES: 'Lagre endringer',
  CHANGES_HAVE_BEEN_SAVED: 'Endringene har blitt lagret.',
  GENERAL_CHANGE_ERROR_HEADING: 'Endringene ble ikke lagret',
  GENERAL_CHANGE_ERROR_TEXT: 'Noe har gått galt, og vi har ikke klart å lagre endringene dine. Prøv igjen senere.',
  MENU: 'Meny',
  PROFILE: 'Profil',
  WALK_LESS_THAN_MINUTE: 'Gå i mindre enn 1 min',
  WALK: 'Gå',
  SEARCH_AGAIN: 'Søk igjen',
  SEARCH_FROM_START_HEADLINE: 'Du må starte søket på nytt',
  SEARCH_FROM_START_TEXT: 'Du må starte søket på nytt etter at du har opprettet profilen din.',
  NEXT_DAY: 'Neste dag',
  NO_JOURNEYS_FOUND_TEXT: 'Vi klarte ikke å finne noen avganger for søket ditt. Ønsker du å forsøke dagen etter?',
  CANCEL_WARNING_HEADER: 'Advarsel!',
  CANCEL_PURCHASE_TEXT: 'Om du velger å forlate denne siden vil informasjonen du allerede har lagt inn slettes.',
  CANCEL_PURCHASE_BACK: 'Tilbake til bestillingen',
  CANCEL_PURCHASE_PROCEED: 'Avbryt bestillingen',
  CANCEL_FORM_TEXT:
    'Endringene er ikke lagret. Om du velger å forlate siden uten å lagre, vil all ny informasjon slettes og tilbakestilles til det du har lagt inn tidligere.',
  CANCEL_FORM_BACK: 'Gå tilbake for å lagre',
  CANCEL_FORM_PROCEED: 'Gå videre uten å lagre',
  CANCEL_TICKET: 'Avbestill',
  CANCEL_THE_TICKET: 'Avbestill billetten',
  CANCEL_TICKET_ERROR_TEXT: 'med kanselleringen av billetten din. Vennligst prøv igjen eller kontakt kundeservice',
  TICKET_IS_CANCELLED: 'Billetten er avbestilt',
  CANCELLED: 'Avbestilt',
  TRY_AGAIN: 'Prøv igjen',
  THIS_IS_EMPTY: 'Her er tomt',
  CANCEL_TICKET_PARAGRAPH:
    'Om du ikke har mulighet til å reise med billetten du har kjøpt, kan du avbestille ved å trykke på knappen under. Har du kjøpt en Fleks-billett vil du få tilbakebetalt hele beløpet frem til 24 timer før avgang. Etter dette vil du pålegges et gebyr på kr. 100.-',
  END_MEMBERSHIP_TEXT: 'Avslutt medlemskapet. Jeg ønsker ikke lenger være med i fordelsprogrammet.',
  CHOOSE_SLEEP_COMPARTMENTS: 'Velg antall kupeer',
  COMPARTMENT: 'Kupé',
  COMPARTMENTS: 'kupeer',
  CUSTOMER_SERVICE_WHEELCHAIR: 'Reiser du med rullestol eller barnevogn?',
  TRAINS_HAVE_WHEELCHAIR_SPACE:
    'Om du ønsker å reservere gulvplass for rullestol eller barnevogn kan du bestille via kundeservice. Husk at du kan reise med barnevogn som bagasje. Da er det ikke nødvendig å bestille plass. Når du ankommer toget pakker du sammen vognen og plasserer den i bagasjeområdet.',
  PHONE_TO_CUSTOMER_SERVICE: 'Telefon til kundeservice:',
  CHANGE_SEAT: 'Endre sete',
  CHOOSE_SEAT: 'Velg sete',
  SEAT_SELECTOR_ERROR_HEAD: 'Vi klarte ikke å åpne setevelgeren',
  SEAT_SELECTOR_ERROR_TEXT:
    'Vi klarte av en eller annen grunn ikke å åpne setevelgeren. Om du velger å gå videre vil du få tildelt sete automatisk.',
  RESERVE_SEAT_ERROR_HEAD: 'Vi klarte ikke å reservere sete',
  RESERVE_SEAT_ERROR_TEXT:
    'Vi klarte av en eller annen grunn ikke å reservere sete. Om du velger å gå videre vil du få tildelt sete automatisk.',
  SEAT_SELECTOR_EMPTY_ERROR_BODY: 'Noe gikk dessverre galt i søket etter ledig seter, vennligst prøv igjen senere.',
  OF: 'av',
  SEATS_IS_CHOOSEN: 'seter er valgt',
  TRAIN_DIRECTION: 'Togets kjøreretning',
  DIRECTION: 'Kjøreretning',
  TOWARDS: 'mot',
  YOU_CAN_CHANGE_SEAT: 'Du kan endre setevalg ved å trykke på ønsket sete. Setereservasjon er gratis.',
  CHOOSEN_SEAT: 'Valgt sete',
  AVAILABLE_SEAT: 'Ledig sete',
  AVAILABLE_SEATS: 'ledige seter',
  NOT_AVAILABLE_SEAT: 'Ikke reserverbart',
  POSSIBLE_WRONG_DIRECTION:
    'Setet eller et av setene du har valgt kan på hele eller deler av strekningen være vendt mot kjøreretningen.',
  WHEEL_CHAIR_SPACE: 'Rullestolplass',
  BABY_CARRIAGE_SPACE: 'Barnevognplass',
  NURSING_SPACE: 'Stellebord',
  PLAYROOM: 'Lekerom',
  WC: 'Toalett',
  CHANGE_RAILCAR: 'Endre vogn',
  CLICK_TO_CHANGE_RAILCAR: 'Klikk i feltet under for å velge en annen vogn.',
  RAIL_CAR: 'Vogn',
  CATEGORY: 'Kategori',
  DEVIATIONS: 'avviksmeldinger',
  SHOW_TRAVELLING_DETAILS: 'Vis reisedetaljer',
  ONLY_ONE_CARRIAGE_MESSAGE: 'For typen du har valgt er det kun ledige seter i denne vognen.',
  READ_MORE: 'Les mer',
  RESERVATION_EXPIRED_HEADLINE: 'Reservasjonsperioden er utløpt. ',
  RESERVATION_EXPIRED_TEXT: 'Om du fremdeles ønsker å bestille billetter må du gjennomføre et nytt søk.',
  CHANGE_DEPARTURE_MESSAGE: 'Vi har endret ditt tidspunkt for utreise.',
  CHANGE_RETURN_MESSAGE: 'Vi har endret ditt tidspunkt for returreise.',
  CHANGE_TRANSFER_MESSAGE:
    'Som følge av at du har byttet overgangstid kan det ha blitt gjort endringer. Vennligst se over ordren din på nytt og at alt stemmer.',
  PAYMENT_SERVICE_ERROR:
    'Noe gikk dessverre galt når vi prøvde å gjennomføre betalingen. Prøv igjen eller kontakt kundeservice for å gjennomføre din bestilling. Tlf: +47 61 25 80 00',
  SMS_CONSENT_TEXT: 'Jeg gir samtykke til å motta billettkoden min på SMS.',
  YOUR_CONSENT_NEEDED: 'Ditt samtykke kreves',
  YOU_MUST_CONSENT_SMS:
    'Du må gi samtykke til å motta billettkoden på SMS. Ditt valg blir lagret i kommunikasjonsinnstillinger, du kan endre dette senere hvis ønskelig.',
  CONSENT_SMS_SUCCESS:
    'Samtykke til å motta billettkoden på SMS er lagret i kommunikasjonsinnstillinger, du kan endre dette senere hvis ønskelig.',
  FAILED_TO_SAVE_CONSENT: 'Vi kunne ikke lagre ditt samtykke.',
  SMS_INFO: 'Du må opprette en konto for å motta en billettkode via SMS',
  GO_TO_PROFILE: 'Gå til profil',
  MODAL_GO_TO_PROFILE_HEADLINE: 'Du kan endre valget i profilen din',
  MODAL_GO_TO_PROFILE_TEXT:
    'Du kan endre valget ditt under Kommunikasjonsinnstillinger i profilen din. Søket ditt avbrytes når du går til profilen din.',
  TICKET_DETAILS_ERROR_TEXT:
    'Vi kunne ikke vise detaljene i billetten din. Prøv igjen senere eller kontakt kundeservice',
  REBOOK_SLEEPER: 'Kontakt vårt kundesenter om du ønsker å endre denne reisen',
  REBOOK_SLEEPER_COMPARTMENT: 'Ombok sove kupé',
  APP_AD_TEXT: 'Visste du at vi har en app? Du finner den ',
  HERE_LINK: 'her',
  OPEN_OVERVIEW: 'Se oversikt over innhold',
  CLOSE_OVERVIEW: 'Lukk oversikt',
  PAGE_TITLE_PLANNER: 'Planlegg din reise',
  PAGE_TITLE_DEPARTURE: 'Velg reise',
  PAGE_TITLE_RETURN: 'Velg retur reise',
  PAGE_TITLE_LOGIN: 'Logg inn eller lag kundeprofil',
  PAGE_TITLE_REVIEW: 'Kontroller billetten',
  PAGE_TITLE_PAYMENT: 'Legg inn betalingsinformasjon',
  PAGE_TITLE_COMPLETE: 'Betaling fullført',
  PAGE_TITLE_PROFILE: 'Min kundeprofil',
  PAGE_TITLE_CREATE_PROFILE: 'Lage kundeprofil',
  PAGE_TITLE_EDIT_PROFILE: 'Endre min kundeprofil',
  PAGE_TITLE_CHANGE_PASSWORD: 'Endre passord',
  PAGE_TITLE_MY_DATA: 'Personvern - behandling av personlige data',
  PAGE_TITLE_DELETE_PROFILE: 'Slett kundeprofil - slett meg',
  PAGE_TITLE_MY_JOURNEYS: 'Mine reiser',
  PAGE_TITLE_MY_CARDS: 'Mine betalingskort',
  PAGE_TITLE_DEFAULT: 'Go-Ahead',
  PAGE_TITLE_NOT_FOUND: 'Siden finnes ikke',
  LABEL_NAVIGATE_TO_START_PAGE: 'Naviger til startsiden',
  LABEL_ADD: 'Legg til',
  LABEL_SUBTRACT: 'Trekk fra',
  LABEL_LOGIN_WITH_EXSISTING: 'Logg inn med eksisterende konto',
  LABEL_CREATE_NEW_PROFILE_INFO: 'Informasjon om å opprette en ny konto',
  LABEL_JOURNEY_INFORMATION: 'Reise informasjon',
  LABEL_SEARCH_RESULT: 'Søkeresultat',
  LABEL_HOW_TO_RECEIVE_TICKET: 'Velg hvordan du skal mottat billetten',
  LABEL_CHOOSE_PAYMENT_METHOD: 'Velg hvordan du ønsker å betale',
  LABEL_ACCEPT_GOAHEAD_TERMS: 'Aksepter Go-Aheads vilkår',
  LABEL_CHOOSE_DESTINATIONS: 'Velg hvor du skal reise fra og til',
  LABEL_SEARCH_DESTINATIONS: 'Søk stasjoner',
  SEARCH_JOURNEY_BTN: 'Finn reise',
  LABEL_CHOOSE_DATE_AND_TIME: 'Velg dato og tid',
  LABEL_CHOOSE_PASSENGERS_AND_SUPPLEMENTS: 'Velg reisende og tilleggsprodukter',
  LABEL_REVIEW_TICKETS: 'Kontroller billetter',
  LABEL_PASSWORD_INFO: 'Informasjon om passordregler',
  LABEL_CHANGE_PASSWORD: 'Endre passordet ditt',
  LABEL_DELETE_ACCOUNT_INFO: 'Informasjon om å slette konto',
  LABEL_PROFILE_INFO: 'Profil informasjon',
  LABEL_ENTER_PROFILE_INFO: 'Fyll inn personinformasjon',
  LABEL_GOAHEAD_CONTACT_INFO: 'Go-Ahead kontakt informasjon',
  LABEL_PROFILE: 'Profil',
  LABEL_CHOOSE_PROFILE_SECTION: 'velg profilseksjonen',
  LABEL_INFO_ABOUT_STORING_CARDS: 'Informasjon om lagring av kort',
  LABEL_LIST_OF_STORED_CARDS: 'Liste over lagrede kort',
  LABEL_COMMS_SETTINGS: 'Kommunikasjonsinnstillinger',
  LABEL_CHOOSE_TICKET_CATEGORY: 'Velg billettkategori',
  LABEL_CLOSE_MODAL: 'Lukk vinduet',
  LABEL_TICKET_SMS: 'Send billettkode på SMS',
  ERROR_ACCEPT_TERMS: 'Du må akseptere Transportvilkårene og Personvernerklæringen for å kunne gå videre.',
  CHAT: 'Chat',
  ONE_SLEEP_COMPARTMENT: '1 kupé',
  SHARED_SLEEP_COMPARTMENT: 'Delt kupé',
  OPEN_CHAT: 'Åpne chat',
  NO_DEVIATIONS_HEADLINE: 'Ingen trafikkmeldinger',
  NO_DEVIATIONS_TEXT:
    'Her var det ikke noe å hente. For øyeblikket ser alt ut til å gå på skinner og det er ikke meldt om noen avvik.',
  ON_GOING_WORK: 'Pågående arbeid',
  PLANNED_WORK: 'Planlagt arbeid',
  ROUTE_HOME: 'Hjem',
  ROUTE_DEVIATIONS: 'Trafikkmeldinger',
  ROUTE_ARTICLES: 'Aktuelt',
  CMS_ERROR_HEADLINE: 'Her gikk det visst ikke helt på skinner',
  CMS_ERROR_LOADING_MAIN_TEXT:
    'Oops! Vi har noen tekniske problemer for øyeblikket. Reisesøk skal likevel fungere fint.',
  OPEN_MENU: 'Åpne meny',
  GO_BACK: 'Gå tilbake',
  SEARCH_COMPONENT_NO_SAME: 'Avgangs- og ankomststasjon kan ikke være like.',
  NO_STATIONS_SYS_ERR_TEXT: 'Det ser ut til at vi ikke klarer å få kontakt med databasen vår.',
  GET_MY_POSITION: 'Få min posisjon',
  FIND_JOURNEY_ENTUR: 'Finn reisen hos Entur',
  LOADING: 'Laster',
  PAGE: 'Siden',
  LIST: 'Liste',
  VALID_FROM: 'Gyldig fra',
  DELETE_STATION: 'Fjern stasjon',
  STATIONS: 'Stasjoner',
  STATION: 'Stasjon',
  SHORTCUTS: 'Snarveier',
  EARLIER_SEARCH: 'Tidligere søk',
  BUY_SORPASSET: 'Kjøp Sørpasset',
  ORDER_ASSISTANCE_REQ: 'Vent venligst, bestiller assistance',
  YES: 'Ja',
  NO: 'Nei',
  DESCRIPTION_OF_NEED: 'Beskrivelse av behov',
  CONTACT_CUSTOMER_SERVICE: 'Ta kontakt med vårt kundesenter',
  BOOK_ASSISTANCE: 'Bestill assistanse',
  SEND_ORDER: 'Send bestillingen',
  ASSISTANCE_LOCATION: 'Hvor behøver du assistanse?',
  ASSISTANCE_NEEDS: 'Assistansebehov',
  ASSISTANCE_AID: 'Hjelpemidler som du benytter',
  TRAVELLING_ALONE: 'Reiser du alene?',
  TOTAL_LUGGAGE: 'Antall kolli (maks 3 kolli)',
  CONFIRMATION_CHOICE: 'Ønsker du bekreftelse på:',
  ASSISTANCE_DEPARTURE_INFO: 'Avreise: Hvor og når ønsker du å bli møtt?',
  ASSISTANCE_DESTINATION_INFO: 'Destinasjon: Hvor ønsker du å bli fulgt?',
  ASSISTANCE_ORDER_SUCCESS_HEADLINE: 'Takk for bestillingen!',
  ASSISTANCE_ORDER_SUCCESS_TEXT: 'Bane Nor vil kontakte deg for videre informasjon. Vi ønsker deg en fin reise!',
  ASSISTANCE_ORDER_CASE_NUMBER: 'Ditt saksnummer er: ',
  CASE_NUMBER: 'Saksnummer',
  CONTACT_BANENOR: 'Ved spørsmål kontakte Bane NORs kundesenter.',
  ASSISTANCE_ORDER_ERROR_TITLE: 'Bestilling av assistanse mislyktes',
  ASSISTANCE_IS_BOOKED: 'Assistanse er bestilt.',
  LEGAL_LIABILITY_ASSISTANCE_1:
    'Bane NOR er behandlingsansvarlig for personopplysninger som samles inn for å tilby assistansetjenester. GoAhead er databehandler, og utfører innsamling av opplysningene på vegne av Bane NOR.',
  LEGAL_LIABILITY_ASSISTANCE_2:
    'Formålet med Bane NORs behandling av personopplysninger, er å kunne tilby assistanse til de reisende som har behov for det. I den forbindelse har Bane NOR behov for informasjon om type assistansebehov, tid, sted og kontaktinformasjon. Slike opplysninger er Bane NOR avhengig av å kunne dele med dem som skal assistere deg, for eksempel togselskap, vektere eller taxi. Når Bane NOR behandler disse opplysningene, er det for å utføre en oppgave i allmennhetens interesse, eller fordi det nødvendig av hensyn til viktige allmenne interesser når det behandles særlige kategorier personopplysninger, slik som helseopplysninger. Bane NOR er pålagt å tilby assistansetjenester etter jernbanepassasjerrettighetsforskriften.',
  LEGAL_LIABILITY_ASSISTANCE_3:
    'Vi ber om at du ikke registrerer mer opplysninger enn det som er nødvendig for å beskrive ditt assistansebehov, og at du så langt som mulig unngår å registrere helseopplysninger. Du kan finne mer informasjon, herunder om dine rettigheter, klagemuligheter og kontaktinformasjon til Bane NORs personvernombud, i Bane NORs ',
  ASSISTANCE_ORDER_ERROR_TEXT:
    'Bestilling av assistanse mislyktes. Sjekk internettilgangen din og prøv igjen. Om du fortsatt ikke får det til, ta kontakt med Go-Aheads kundeservice.',
  ASSISTANCE_LOAD_FORM_ERROR:
    'Det er dessverre ikke mulig å laste inn assistansebestillingen akkurat nå. Sjekk internettilgangen din og prøv igjen. Om du fortsatt ikke får det til, ta kontakt med Go-Aheads kundeservice.',
  ASSISTANCE_TOO_LATE:
    'Det er dessverre ikke mulig å booke assistanse via nett/app for din reise. Dette må gjøres innen 24 timer før avreise. Men vi hjelper deg gjerne med å booke dette om du kontakter vårt kundesenter.',
  SORPASSET_SALE_IS_OVER: 'Salgsperioden for Sørpasset er nå over for denne gang.',
  SORPASSET_LOGIN_HEAD: 'Du må logge inn for å kunne booke Sørpasset',
  SORPASSET_LOGIN_TEXT:
    'Du må logge inn for å kjøpe Sørpasset. Dette må du gjøre for at vi kan gi deg rabatt når du booker plassbilletter senere.',
  SR_OPEN_TRANSPORT_TAB: 'Åpne transportvilkårene i en ny fane',
  SR_OPEN_INTEGRITY_TAB: 'Åpne personvernvilkårene i en ny fane',
  SR_OPEN_IN_NEW_TAB: 'Åpne ${page} i en ny fane',
  SR_PICK_STARTDATE: 'Velg start dato',
  ORDER: 'Bestill',
  SEND: 'Send',
  SEND_AGAIN: 'Send igjen',
  SEND_TICKET_AGAIN: 'Send billetten på nytt',
  MAIL_SUBJECT_MISSING_TICKET: 'Mangler billett på denne ordren',
  ERROR_SEND_PDF_HEAD: 'Beklager vi klarer ikke sende billetten til deg.',
  SUCCESS_SEND_PDF_HEAD: 'Billetten er sendt',
  SUCCESS_SEND_PDF_TXT:
    'Vi klarte å sende billetten til deg nå, sjekk innboksen eller søplboksen din om du kan finne den der.',
  CUSTOMER_SERVICE_TXT1: 'Hvis du fortsatt har problemer kontakt kundeservice',
  CUSTOMER_SERVICE_TXT2: 'Kontakt kundeservice så vil de hjelpe deg.',
  DOWNLOAD_APP: 'Last ned Go-Ahead appen',
  SKIP_TO_CONTENT: 'Hopp til hovedinnhold',
  VIPPS_TIMEOUT_TITLE: 'Betalingen tar lengre tid end vanlig',
  VIPPS_TIMEOUT_TEXT: 'Din betaling med Vipps ser dessverre ut til å ta litt lengre tid enn vanlig.',
  VIPPS_TIMEOUT_PDF_TEXT: 'Sjekk om du har fått billetten og kvitteringen om et par minutter.',
  SEE_ALL_DEVIATIONS: 'Se alle trafikkmeldinger',
  CRITICAL_DEVIATIONS: 'Varsler',
  REQUEST_DEVIATIONS_ERROR:
    'Noe gikk dessverre galt da vi prøvde å hente trafikkmeldninger, vennligst prøv igjen senere eller kontakt kundeservice.',
  ONLY_VALID: 'Gjelder kun',
  TODAY: 'I dag',
  PURCHASE_CANCELLED: 'Kjøpet ble avbrutt',
  GO_BACK_AND_TRY_AGAIN: 'Gå tilbake og prøv igjen.',
  BUS_FOR_TRAIN: 'Buss for tog',
  IS_TRAIN_ON_TIME: 'Sjekk om toget er i rute',
  IS_TRAIN_ON_TIME_PREAMBLE: 'Er toget i rute?',
  BETWEEN: 'mellom',
  ONE_WAY: `En vei`,
  TURN_RETURN: `Tur/Retur`,
  CUSTOMER_NUMBER: 'Kundenummer',
  HOURS_SHORT: 't',
  MINUTES_SHORT: 'min',
  HANDLING_PERSONAL_INFO: 'Behandling av personopplysninger',
  ACCEPT_CONDITIONS_TEXT: 'Jeg har lest og akseptert Go-Aheads Transportvilkår og Personvernerklæring',
  COOKIES_TITLE: 'Informasjonskapsler',
  LANGUAGE: 'Språk',
  SELECT_LANGUAGE: 'Velg språk',
  ZIPCODE: '0000',
  ADD_DISCOUNT_CODE: 'Legg till rabattkode',
  SAVE: 'Lagre',
  HAVE_DISCOUNT_CODE: 'Har du en rabattkode?',
  MY_DISCOUNT_CODES: 'Mine rabatter',
  ADD_DISCOUNT_ERROR: 'Koden er enten ugyldig eller har allerede blitt lagt til kontoen din.',
  YOUR_ORDER_STEP: 'Your order step ',
  UNTIL: 'til',
  PLEASE_WAIT: 'Vennligst vent',
  ADD_DISCOUNT_SUCCESS: 'Koden er lagret på din konto',
  NOT_FOUND_HEADLINE: 'Oops',
  NOT_FOUND_PREAMBLE:
    'Det ser ut som siden har blitt flyttet eller ikke eksisterer lengre. Kanskje du kan finne det du ser etter her?',
  COMPARTMENT_NOT_AVAILABLE: 'Det er dessverre ikke nok sovekupeer tilgjengelig.',
  COMPARTMENT_ERROR: 'På grunn av en teknisk feil kan vi dessverre ikke endre antall sovekupeer.',
  ENTUR_MEMBER_ID: 'Entur Medlems-ID (valgfritt)',
  REMEBER_TO_CHECK_ENTUR_ID: 'Husk å dobbeltsjekke at din entur-ID er riktig',
  STRAWBERRY_EMAIL: 'Strawberry E-post',
  STRAWBERRY_PHONE: 'Strawberry Telefon (norsk)',
  STRAWBERRY_ID: 'Strawberry ID',
  STRAWBERRY_UNVERIFIED: 'Du er ikke verifisert',
  STRAWBERRY_VERIFIED: 'Du er verifisert og trenger ikke gjøre mer',
  STRAWBERRY_INFORMATION: 'Strawberry informasjon',
  STRAWBERRY_BONUS: 'Strawberry bonus',
  STRAWBERRY_SLUG: 'strawberry',
  STRAWBERRY_VERIFICATION_ERROR:
    'Vi kunne ikke finne noen med den strawberry id. Vennligst dobbeltsjekk strawberry id og e-post (eller telefon)',
  VERIFY: 'Verifiser',
  STRAWBERRY_MAIL_OR_PHONE_ERROR: 'Du må fylle inn enten e-post eller telefonnummer. Ikke begge',
  CHOOSE_STATION: 'Velg en stasjon',
  FREIGHT_TRAIN: 'Godstog',
  OFFER_LOW_PRICE_TRANSLATION: 'Lavpris',
  OFFER_LOW_PRICE_DESCRIPTION: 'Ingen endring eller refusjon',
  OFFER_FLEKS_TRANSLATION: 'Fleks',
  OFFER_FLEKS_DESCRIPTION: 'Kan refunderes',
  PRODUCT_STANDARD_DESCRIPTION: 'Dette er vårt standardsete, og vårt billigste alternativ',
  PRODUCT_EXTRA_TRANSLATION: 'Ekstra',
  PRODUCT_EXTRA_DESCRIPTION:
    'Ekstra er for deg som ønsker det lille ekstra. Nytraktet kaffe, snackbar og sete i togets roligste avdeling.',
  PRODUCT_FAMILY_TRANSLATION: 'Familie',
  PRODUCT_FAMILY_DESCRIPTION:
    'Familie er for deg som reiser med barn. Her blir du plassert i en egen vogn med lekerom og stellebord på toalettene.',
  PRODUCT_NOPETSALLOWED_TRANSLATION: 'Dyrefri',
  PRODUCT_NOPETSALLOWED_DESCRIPTION: 'I vår dyrefrie vogn kan ingen dyr oppholde seg, selv ikke førerhunder.',
  PRODUCT_REST_TRANSLATION: 'Hvile',
  PRODUCT_REST_DESCRIPTION:
    'Med Hvile får du tildelt en komfortabel lenestol som sikrer en avslappende reise i togets hvilevogn. Snackbar og nykvernet kaffe er inkludert i billetten.',
  PRODUCT_SLEEP_TRANSLATION: 'Sove',
  PRODUCT_SLEEP_DESCRIPTION: 'Reis i egen sovekupé - En kupé består av to oppredde senger på 75 cm bredde',
  GOA_SINGLE_TICKET_NAME_TRANSLATION: 'Enkeltbillett',
  GOA_SINGLE_TICKET_NAME_WARNING: 'Vær oppmerksom på at disse billetter ikke kan endres eller refunderes',
  GOA_NO_ANIMAL_FREE_TICKET_WARNING: 'Vi gjør oppmerksom på at det ikke finnes dyrefri avdeling på lokale avganger',
  GOA_CONSCRIPT_TICKET_WARNING: 'Rabatten for vernepliktige gjelder kun for Standard',
  GOA_BIKE_TICKET_WARNING:
    'Vær oppmerksom på at sykkelen ikke kan tas med til setet, men plasseres på egne sykkelplasser. Ombordpersonalet vil hjelpe deg',
};
