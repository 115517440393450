import { Order } from 'types/OrderTypes';
import { Passengers, Supplements } from 'types/PassengerTypes';
import { GOADate } from 'utils/date/GOADate';
import { Constants } from './Constants';
import { JourneyLegType, JourneyType, ProductType, OfferType, ItemType, OfferLegType } from 'types/JourneyTypes';
import { AssistanceLocationType } from 'types/LocationTypes';
import AES from 'crypto-js/aes';
import { enc } from 'crypto-js';
import { DeviationMessage } from 'types/DeviationTypes';

export const SORT_ORDER_PASSENGERS = [
  Constants.GOA_USERPROFILE_ADULT,
  Constants.KOL_USERPROFILE_ADULT,
  Constants.GOA_USERPROFILE_INFANT,
  Constants.KOL_USERPROFILE_INFANT,
  Constants.GOA_USERPROFILE_CHILD,
  Constants.KOL_USERPROFILE_CHILD,
  Constants.GOA_USERPROFILE_STUDENT,
  Constants.KOL_USERPROFILE_STUDENT,
  Constants.GOA_USERPROFILE_SENIOR,
  Constants.KOL_USERPROFILE_SENIOR,
  Constants.GOA_USERPROFILE_MILITARY,
  Constants.KOL_USERPROFILE_MILITARY,
];

export const getDividedOfferByLeg = (leg: OfferLegType) =>
  Object.values(
    leg.product.items.reduce((a, curr) => {
      (a[curr.datedServiceJourneyIds.join('')] = a[curr.datedServiceJourneyIds.join('')] || []).push(curr);
      return a;
    }, []),
  );

export const chunks = (array: ItemType[], size: number): any[] => {
  const copy = [...array];
  const acc = [];

  while (copy.length) {
    acc.push(copy.splice(0, size));
  }
  return acc;
};

export const formatShortPrice = (price: string | number): string => `kr. ${price.toString().split('.')[0]},-`;

export const times = (num: number, f: () => string, acc = []): string[] => {
  if (num === 0) return acc;

  acc.push(f());
  return times(num - 1, f, acc);
};

export const calculatePassengers = (passengers: Passengers | Supplements): number => {
  let total = 0;

  for (const key in passengers) {
    total += passengers[key];
  }
  return total;
};

export const checkIsSamePassenger = (filtered: Passengers | Supplements): boolean => {
  const passengerTypesArray = Object.keys(filtered).filter((pass) => filtered[pass] !== 0);
  return Boolean(passengerTypesArray.length === 1);
};

export const getJourneyDistance = (journey): number => {
  let totalDistance = 0;
  journey.legs.forEach((leg) => {
    totalDistance += leg.distance;
  });
  return totalDistance;
};

export const formatDistanceKm = (journey): string => {
  return `${(getJourneyDistance(journey) / 1000).toFixed(2)} km`;
};

// Convert Nested Types
// ======================

export const replaceTypesInDataStructure = (obj: any): any => {
  // Add more types here as needed
  if (typeof obj === 'string' && obj.length > 10 && GOADate.isValidISO(obj)) return new GOADate(obj);

  if (Array.isArray(obj)) return obj.map((v) => replaceTypesInDataStructure(v));
  if (obj === Object(obj))
    return Object.entries(obj).reduce((acc, entry) => {
      const [k, v] = entry;
      obj[k] = replaceTypesInDataStructure(v);
      return obj;
    }, obj);
  return obj;
};

export const processResponse = (_, res: any): Promise<any> => {
  if (!res.error) {
    const upd = replaceTypesInDataStructure(res);
    return Promise.resolve({ res: upd });
  }
  return Promise.reject({ res });
};

export const getGoaName = (name: string): string => {
  switch (name) {
    case Constants.NOPETSALLOWED:
      return Constants.GOA_NOPETSALLOWED;
    case Constants.FAMILY:
      return Constants.GOA_FAMILY;
    case Constants.SMALL_ANIMAL:
      return Constants.GOA_SMALL_ANIMAL;
    case Constants.ANIMAL:
      return Constants.GOA_ANIMAL;
    case Constants.BICYCLE:
      return Constants.GOA_BICYCLE;
    case Constants.INFANT:
      return Constants.GOA_USERPROFILE_INFANT;
    case Constants.ADULT:
      return Constants.GOA_USERPROFILE_ADULT;
    case Constants.STUDENT:
      return Constants.GOA_USERPROFILE_STUDENT;
    case Constants.CHILD:
      return Constants.GOA_USERPROFILE_CHILD;
    case Constants.SENIOR:
      return Constants.GOA_USERPROFILE_SENIOR;
    case Constants.MILITARY:
      return Constants.GOA_USERPROFILE_MILITARY;
    default:
      return '';
  }
};

export const mapPassengerToGOAId = (goaId: string): string => {
  switch (goaId) {
    case Constants.GOA_NOPETSALLOWED:
      return Constants.NOPETSALLOWED;
    case Constants.GOA_FAMILY:
      return Constants.FAMILY;
    case Constants.GOA_SMALL_ANIMAL:
      return Constants.SMALL_ANIMAL;
    case Constants.GOA_ANIMAL:
      return Constants.ANIMAL;
    case Constants.GOA_BICYCLE:
      return Constants.BICYCLE;
    case Constants.GOA_BICYCLE_NON_RESERVABLE:
      return Constants.BICYCLE;
    case Constants.GOA_USERPROFILE_INFANT:
    case Constants.KOL_USERPROFILE_INFANT:
      return Constants.INFANT;
    case Constants.GOA_USERPROFILE_ADULT:
    case Constants.KOL_USERPROFILE_ADULT:
      return Constants.ADULT;
    case Constants.GOA_USERPROFILE_STUDENT:
    case Constants.KOL_USERPROFILE_STUDENT:
      return Constants.STUDENT;
    case Constants.GOA_USERPROFILE_CHILD:
    case Constants.KOL_USERPROFILE_CHILD:
      return Constants.CHILD;
    case Constants.GOA_USERPROFILE_SENIOR:
    case Constants.KOL_USERPROFILE_SENIOR:
      return Constants.SENIOR;
    case Constants.GOA_USERPROFILE_MILITARY:
    case Constants.KOL_USERPROFILE_MILITARY:
      return Constants.MILITARY;
    default:
      return '';
  }
};

export const getSuppTranslation = (goaId: string): string => {
  switch (goaId) {
    case Constants.GOA_BICYCLE:
    case Constants.GOA_BICYCLE_NON_RESERVABLE:
      return 'PASSENGER_TYPE_BICYCLE_NAME';
    case Constants.GOA_SMALL_ANIMAL:
      return 'PASSENGER_TYPE_SMALL_ANIMAL_NAME';
    case Constants.GOA_ANIMAL:
      return 'PASSENGER_TYPE_ANIMAL_NAME';
    case Constants.GOA_SLEEPER:
      return 'ONE_SLEEP_COMPARTMENT';
    case Constants.GOA_SLEEPER_BERTH:
    case Constants.GOA_SLEEPER_CHILD:
      return 'SHARED_SLEEP_COMPARTMENT';
    default:
      break;
  }
};

export const checkSubMode = (journey: JourneyType): boolean => {
  let res = false;
  journey.legs.some((leg) => {
    if (leg.transportSubmode === Constants.LEG_SUB_MODE_BUS) res = true;
  });
  return res;
};

export const checkHasSeatSelector = (order: Order): boolean => {
  if (order.supplements.BICYCLE > 0) return false;
  return (
    order.journey.selectedJourney.legs.some((l) => l.seatReservationAvailable) &&
    order.journey.selectedOffer.legs.some((l) => l.product && l.product.seatMapAvailable)
  );
};

export const checkNightTrain = (journey: JourneyType): boolean => {
  let res = false;
  journey.legs.forEach((leg) => {
    if (leg.transportSubmode === Constants.LEG_SUB_MODE_NIGHT_TRAIN) res = true;
  });
  return res;
};

export const checkHasAssistance = (from: string, to: string, assistanceLocations: AssistanceLocationType[]): boolean =>
  assistanceLocations && assistanceLocations.some((loc) => loc.id === from || loc.id === to);

export const isLocalTrainOnly = (journey: JourneyType): boolean =>
  journey.legs.every(
    (leg: JourneyLegType) => leg.lineId === Constants.LINE_ID_ARENDAL || leg.lineId === Constants.LINE_ID_KOL,
  );

export const hasRegionLeg = (order: Order): boolean =>
  order.journey.selectedJourney.legs.some((l) => l.lineId && l.lineId === Constants.LINE_ID_REGION);

export const getCompartmentPrice = (product: ProductType): number => {
  return (product.sleeperCompartments - product.minimumSleepers) * Number(product.sleeperPriceNOK);
};

export const getOfferPrice = (offer: OfferType): number => {
  let totalAmount = 0;
  offer.legs.forEach((leg) => {
    totalAmount += Number(leg.product.priceNOK);
    if (!leg.product.sleeperAsAProduct && leg.product.sleeperCompartments)
      totalAmount += getCompartmentPrice(leg.product);
  });
  return totalAmount;
};

export const getTotalAmount = (order: Order): number => {
  if (order.returnJourney)
    return getOfferPrice(order.journey.selectedOffer) + getOfferPrice(order.returnJourney.selectedOffer);
  return getOfferPrice(order.journey.selectedOffer);
};

export const getOfferProducts = (offer: OfferType): string[] => {
  return offer.legs.map((leg) => leg.product.name);
};

export const isJourneyLateDeparture = (journey: JourneyType): boolean =>
  Boolean(journey.expectedDepartureUtc) &&
  journey.expectedDepartureUtc.isAfter(journey.plannedDepartureUtc) &&
  (journey.expectedDepartureUtc.getMinutes() !== journey.plannedDepartureUtc.getMinutes() ||
    journey.expectedDepartureUtc.getHours() !== journey.plannedDepartureUtc.getHours());

export const isJourneyLateArrival = (journey: JourneyType): boolean =>
  Boolean(journey.expectedArrivalUtc) &&
  journey.expectedArrivalUtc.isAfter(journey.plannedArrivalUtc) &&
  (journey.expectedArrivalUtc.getMinutes() !== journey.plannedArrivalUtc.getMinutes() ||
    journey.expectedArrivalUtc.getHours() !== journey.plannedArrivalUtc.getHours());

export const isDelayedJourney = (journey: JourneyType): boolean => {
  return isJourneyLateDeparture(journey) || isJourneyLateArrival(journey);
};

export const isJourneyDone = (journey: JourneyType): boolean =>
  Boolean((journey.expectedArrivalUtc || journey.plannedArrivalUtc).isBefore(new GOADate()));

// Construct passengers and luggage for backend calls
export const constructPassengers = (passengers: Passengers): string[] => {
  return Object.entries(passengers).reduce((acc, entry) => {
    const [key, num] = entry;
    return times(Number(num), () => key.toUpperCase(), acc);
  }, []);
};

export const constructSupplements = (supplements: Supplements): string[] => {
  return Object.entries(supplements).reduce((acc: string[], entry: [string, number]): string[] => {
    const [key, num] = entry;
    return times(Number(num), () => key.toUpperCase(), acc);
  }, []);
};

export const getPassengersArray = (passengers: Passengers) => {
  const passengerList = [];
  Object.entries(passengers).forEach(([key, value]) => {
    passengerList.push({ [key]: value });
  });
  return passengerList;
};

export const getSupplementsArray = (supplements: Supplements) => {
  const supplementsList = [];
  Object.entries(supplements).forEach(([key, value]) => {
    supplementsList.push({ [key]: value });
  });
  return supplementsList;
};

export const getTrainNumbers = (legs: JourneyLegType[]): number[] => {
  return legs
    .map((leg) => {
      if (leg.trainNumber) return leg.trainNumber;
    })
    .filter((train) => train);
};

export const calcTimeFromEpoc = (startTime, endTime) => {
  const diff = endTime - startTime;
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const mins = Math.floor((diff / (1000 * 60)) % 60);
  return { hours: hours, mins: mins };
};

export const isRouteRebook = (): boolean => window.location.pathname === Constants.ROUTE_MY_JOURNEYS;

export const isSupportedAppOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return Constants.SYSTEM_ANDROID;
  }

  if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
    return Constants.SYSTEM_IOS;
  }

  return undefined;
};

export const getAppStoreUrl = () => {
  if (isSupportedAppOS() === Constants.SYSTEM_ANDROID) {
    return 'https://play.google.com/store/apps/details?id=gan.goalive.app';
  }

  if (isSupportedAppOS() === Constants.SYSTEM_IOS) {
    return 'https://apps.apple.com/us/app/go-ahead-norge-as/id1515079227';
  }
};

export const isProd = () =>
  window.location.host === Constants.GO_AHEAD_PROD_HOST_1 ||
  window.location.host === Constants.GO_AHEAD_PROD_HOST_2 ||
  window.location.host === Constants.GO_AHEAD_PROD_HOST_3 ||
  window.location.host === Constants.GO_AHEAD_PROD_HOST_4;

export const isLocalHost = () => window.location.host === Constants.GO_AHEAD_LOCAL_HOST;

export const isValidDeviationTime = (start, end) => {
  const currentTime = new GOADate();
  const launchDate = new GOADate(start);
  const endDate = new GOADate(end);
  return (
    (launchDate.isBefore(currentTime) || launchDate.isSame(currentTime)) &&
    (endDate.isAfter(currentTime) || endDate.isSame(currentTime))
  );
};

export const isWebview = () => {
  const userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) return !safari; // true for webview and false for browser
  return userAgent.includes('wv');
};

export const removeStation = (station: string) => station.replace(Constants.STATION, '').trim();
export const encryptString = (str: string) => {
  const b64 = AES.encrypt(str, process.env.SECRET).toString();
  const e64 = enc.Base64.parse(b64);
  const eHex = e64.toString(enc.Hex);
  return eHex;
};
export const decryptString = (str: string): string => {
  const reb64 = enc.Hex.parse(str);
  const bytes = reb64.toString(enc.Base64);
  const decrypt = AES.decrypt(bytes, process.env.SECRET);
  const plain = decrypt.toString(enc.Utf8);
  return plain;
};

export const getStaticPageTitle = (route: string, translate: (key: string) => string): string => {
  switch (route) {
    case Constants.ROUTE_BASE:
      return translate('PAGE_TITLE_DEFAULT');
    case Constants.ROUTE_PLANNER:
      return translate('PAGE_TITLE_PLANNER');
    case Constants.ROUTE_JOURNEY:
      return translate('PAGE_TITLE_DEPARTURE');
    case Constants.ROUTE_RETURN_JOURNEY:
      return translate('PAGE_TITLE_RETURN');
    case Constants.ROUTE_LOGIN_PROCEED:
      return translate('PAGE_TITLE_LOGIN');
    case Constants.ROUTE_REVIEW:
      return translate('PAGE_TITLE_REVIEW');
    case Constants.ROUTE_PAYMENT:
      return translate('PAGE_TITLE_PAYMENT');
    case Constants.ROUTE_PROFILE_LANDING:
      return translate('PAGE_TITLE_PROFILE');
    case Constants.ROUTE_CREATE_PROFILE:
      return translate('PAGE_TITLE_CREATE_PROFILE');
    case Constants.ROUTE_EDIT_PROFILE:
      return translate('PAGE_TITLE_EDIT_PROFILE');
    case Constants.ROUTE_CHANGE_PASSWORD:
      return translate('PAGE_TITLE_CHANGE_PASSWORD');
    case Constants.ROUTE_PROFILE_DATA:
      return translate('PAGE_TITLE_MY_DATA');
    case Constants.ROUTE_DELETE_PROFILE:
      return translate('PAGE_TITLE_DELETE_PROFILE');
    case Constants.ROUTE_MY_JOURNEYS:
      return translate('PAGE_TITLE_MY_JOURNEYS');
    case Constants.ROUTE_MY_CARDS:
      return translate('PAGE_TITLE_MY_CARDS');
    case Constants.ROUTE_RESPONSE_PARAM:
      return translate('PAGE_TITLE_DEFAULT');
    case '/preview/start':
      return translate('PAGE_TITLE_DEFAULT');
    case Constants.ROUTE_NETS_REDIRECT:
    case Constants.ROUTE_VIPPS_REDIRECT:
      return translate('PAGE_TITLE_COMPLETE');
    default:
      return '';
  }
};

export const handleDateConversion = (deviationsObj: DeviationMessage[]) => {
  return deviationsObj.forEach((deviation) => {
    deviation.fromDateTime = new GOADate(deviation.fromDateTime as any);
    deviation.untilDateTime = new GOADate(deviation.untilDateTime as any);
    deviation.updatedTime = new GOADate(deviation.updatedTime as any);
  });
};

export const getProductName = (name: string, translate: any): string => {
  switch (name) {
    case Constants.PRODUCT_STANDARD:
      return name;
    case Constants.PRODUCT_SLEEP:
      return translate('PRODUCT_SLEEP_TRANSLATION');
    case Constants.PRODUCT_FAMILY:
      return translate('PRODUCT_FAMILY_TRANSLATION');
    case Constants.PRODUCT_REST:
      return translate('PRODUCT_REST_TRANSLATION');
    case Constants.PRODUCT_NOPETSALLOWED:
      return translate('PRODUCT_NOPETSALLOWED_TRANSLATION');
    case Constants.PRODUCT_EXTRA:
      return translate('PRODUCT_EXTRA_TRANSLATION');
    case Constants.GOA_SINGLE_TICKET_NAME:
      return translate('GOA_SINGLE_TICKET_NAME_TRANSLATION');

    default:
      return name;
  }
};

export const getProductDescription = (name: string, description: string, translate: any): string => {
  switch (name) {
    case Constants.PRODUCT_STANDARD:
      return translate('PRODUCT_STANDARD_DESCRIPTION');
    case Constants.PRODUCT_SLEEP:
      return translate('PRODUCT_SLEEP_DESCRIPTION');
    case Constants.PRODUCT_FAMILY:
      return translate('PRODUCT_FAMILY_DESCRIPTION');
    case Constants.PRODUCT_REST:
      return translate('PRODUCT_REST_DESCRIPTION');
    case Constants.PRODUCT_NOPETSALLOWED:
      return translate('PRODUCT_NOPETSALLOWED_DESCRIPTION');
    case Constants.PRODUCT_EXTRA:
      return translate('PRODUCT_EXTRA_DESCRIPTION');

    default:
      return description;
  }
};
