const colorCodes = {
  white: '#ffffff',
  woodSmoke: '#0f1010',
  mako: '#404548',
  athensGrey: '#fafbfc',
  athensDark: '#f5f6f8',
  mystic: '#eaedf2',
  iron: '#dadde0',
  chathamBlue: '#12576f',
  totemRed: '#a20707',
  backdrop: 'rgba(78, 90, 94, 0.5);',
  containerShadow: '0 0 2rem 0 rgba(0, 0, 0, 0.3);',
  lightCyan: '#e3f7fc',
  jaggedIce: '#d1e8ef',
  amour: '#FAF0F4',
  regentBlue: '#b1dbe8',
  tuna: '#353649',
  vanillaIce: '#f4d8da',
  loblolly: '#bcc2c9',
  submarine: ' #bfc9cc',
  eden: '#0e4a5d',
  tarawera: '#0c4050',
  cornflower: '#047aa3',
  milanoRed: '#be0707',
  orientalPink: '#D3A092',
  Tundora: '#454545',
  colonialWhite: '#FDF9DD',
  chamois: '#ebe5ae',
  turbo: '#ffe100',
};

export default {
  colDark: colorCodes.woodSmoke,
  txtColDark: colorCodes.tuna,
  txtColMedium: colorCodes.mako,
  txtColLight: colorCodes.white,
  txtColAccent: colorCodes.chathamBlue,
  txtColSecondaryAccent: colorCodes.totemRed,
  txtColPlaceholder: colorCodes.iron,
  txtColGrey: colorCodes.Tundora,
  txtColFirstAccent: colorCodes.tuna,
  bgAthensDark: colorCodes.athensDark,
  accentPrimary: colorCodes.chathamBlue,
  hoverAccentPrimary: colorCodes.cornflower,
  disabledAccentPrimary: colorCodes.submarine,
  accentSecondary: colorCodes.totemRed,
  hoverAccentSecondary: colorCodes.milanoRed,
  disabledAccentSecondary: colorCodes.orientalPink,
  bgPrimary: colorCodes.athensGrey,
  bgSecondary: colorCodes.white,
  bgEden: colorCodes.eden,
  bgTarawera: colorCodes.tarawera,
  bgHover: colorCodes.athensDark,
  bgError: colorCodes.amour,
  bgInfo: colorCodes.lightCyan,
  bgNeutral: colorCodes.athensDark,
  bgExpired: colorCodes.amour,
  bgSecondaryAccent: colorCodes.totemRed,
  borderError: colorCodes.vanillaIce,
  borderExpired: colorCodes.vanillaIce,
  borderDeviation: colorCodes.chamois,
  borderColPrimary: colorCodes.iron,
  borderMako: colorCodes.mako,
  borderInputFocus: colorCodes.loblolly,
  bgAmour: colorCodes.amour,
  bgTuna: colorCodes.tuna,
  bgIconPrimary: colorCodes.iron,
  borderColAccent: colorCodes.chathamBlue,
  borderInfo: colorCodes.jaggedIce,
  borderColLight: colorCodes.white,
  borderColFocus: colorCodes.regentBlue,
  backdrop: colorCodes.backdrop,
  containerShadow: colorCodes.containerShadow,
  error: colorCodes.totemRed,
  warning: colorCodes.amour,
  info: colorCodes.lightCyan,
  disabled: colorCodes.submarine,
  bgMystic: colorCodes.mystic,
  linkActive: colorCodes.totemRed,
  readOnly: colorCodes.mako,
  neutralInfo: colorCodes.mystic,
  deviation: colorCodes.colonialWhite,
  deviationIcon: colorCodes.turbo,
};
