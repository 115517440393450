import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import deviations from './deviations/deviationsReducer';
import locations from './locations/locationsReducer';
import order from './order/orderReducer';
import messages from './messages/messagesReducer';
import global from './global/globalReducer';
import user from './user/userReducer';

const rootReducer = combineReducers({
  deviations,
  locations,
  order,
  messages,
  global,
  user,
});

export type AppState = ReturnType<typeof rootReducer>;

export default createStore(rootReducer, applyMiddleware(thunk));
