import React from 'react';
import { Constants } from 'utils/Constants';
import { PortableText } from '@portabletext/react';
import { BoxBodyPortableText } from 'components/new-design/portable-texts/BoxBodyPortableText';
import { CampaignBox } from 'components/new-design/cms/campaigns/CampaignBox';
import { ArticleBox } from 'components/new-design/cms/ArticleBox';
import { InfoBox } from 'components/new-design/cms/InfoBox';
import { TwoColumn } from 'components/new-design/cms/TwoColumn';
import { ThreeColumn } from 'components/new-design/cms/ThreeColumn';
import { ImageTextWrap } from 'components/new-design/cms/ImageTextWrap';
import { StandaloneImage } from 'components/new-design/cms/StandaloneImage';
import { SectionStationSearch } from 'components/section-station-search/SectionStationSearch';
import { Video } from 'components/new-design/cms/Video';
import {
  LargeTopContainer,
  LargeWidthLargeTop,
  LargeWidthLargeTopCenterSmall,
  SmallWidthSmallTop,
  SmallContainer,
  CaptionContainer,
} from 'components/new-design/layouts/LayoutContainers';
import { LinkMediumRespInline } from 'elements/new-design/links/Links';
import {
  ParagraphText,
  ParagraphTextItalic,
  TxtXLargeBoldDarkResp,
  TxtLargeBoldDarkResp,
  TxtMediumBoldDarkResp,
  TxtSmallRegularDarkResp,
} from 'elements/new-design/Typography';
import { UnorderedList, NumberedList, StyledListItem } from 'elements/new-design/cms/List';

export const BodyPortableText = {
  types: {
    figure: ({ value }) => {
      return (
        <SmallContainer>
          <LargeTopContainer>
            <StandaloneImage imageData={value} height={400} />
          </LargeTopContainer>
        </SmallContainer>
      );
    },
    campaignBoxSection: ({ value }) => {
      return (
        <LargeWidthLargeTop position={value.position ? value.position : 'center'}>
          <CampaignBox
            position={value.position ? value.position : 'center'}
            layout="row"
            boxData={value.referencedCampaign}
          />
        </LargeWidthLargeTop>
      );
    },
    infoBoxSection: ({ value }) => {
      return (
        <LargeWidthLargeTopCenterSmall position={value.position ? value.position : 'center'}>
          <InfoBox layout="row" boxData={value.referencedInfoBox}>
            <PortableText value={value.referencedInfoBox.body} components={BoxBodyPortableText} />
          </InfoBox>
        </LargeWidthLargeTopCenterSmall>
      );
    },
    articleBoxSection: ({ value }) => {
      return (
        <LargeWidthLargeTop position={value.position ? value.position : 'center'}>
          <ArticleBox
            position={value.position ? value.position : 'center'}
            layout="row"
            boxData={value.referencedArticleBox}
          />
        </LargeWidthLargeTop>
      );
    },
    stationSearch: () => {
      return (
        <SmallWidthSmallTop position="center">
          <SectionStationSearch
            small="100%"
            medium="100%"
            large="80rem"
            displaySwap={false}
            displaySubmit={true}
            redirectUrl={Constants.ROUTE_PLANNER}
          />
        </SmallWidthSmallTop>
      );
    },
    twoColumn: ({ value }) => {
      return (
        <SmallContainer>
          <LargeTopContainer>
            <TwoColumn boxes={value.twoColumnSections} />
          </LargeTopContainer>
        </SmallContainer>
      );
    },
    threeColumn: ({ value }) => {
      return (
        <SmallContainer>
          <LargeTopContainer>
            <ThreeColumn boxes={value.threeColumnSections} />
          </LargeTopContainer>
        </SmallContainer>
      );
    },
    imageTextWrap: ({ value }) => {
      return (
        <SmallWidthSmallTop>
          <ImageTextWrap box={value} />
        </SmallWidthSmallTop>
      );
    },
    video: ({ value }) => {
      return (
        <>
          <SmallContainer>
            <LargeTopContainer>
              <Video url={value.video.url} height={45} />
            </LargeTopContainer>
            <CaptionContainer>
              <p>
                <TxtSmallRegularDarkResp>{value.video.caption}</TxtSmallRegularDarkResp>
              </p>
            </CaptionContainer>
          </SmallContainer>
        </>
      );
    },
  },
  block: {
    h2: ({ children }: any) => (
      <SmallContainer>
        <LargeTopContainer>
          <h2 id={children[0]}>
            <TxtXLargeBoldDarkResp>{children}</TxtXLargeBoldDarkResp>
          </h2>
        </LargeTopContainer>
      </SmallContainer>
    ),
    h3: ({ children }: any) => (
      <SmallWidthSmallTop>
        <h3>
          <TxtLargeBoldDarkResp>{children}</TxtLargeBoldDarkResp>
        </h3>
      </SmallWidthSmallTop>
    ),
    h4: ({ children }: any) => (
      <SmallWidthSmallTop>
        <h4>
          <TxtMediumBoldDarkResp>{children}</TxtMediumBoldDarkResp>
        </h4>
      </SmallWidthSmallTop>
    ),
    normal: ({ children }: any) => (
      <SmallWidthSmallTop>
        <ParagraphText>{children}</ParagraphText>
      </SmallWidthSmallTop>
    ),
    blockquote: ({ children }: any) => (
      <SmallWidthSmallTop>
        <blockquote>
          <ParagraphTextItalic>{children}</ParagraphTextItalic>
        </blockquote>
      </SmallWidthSmallTop>
    ),
  },
  marks: {
    link: (props: any) => <LinkMediumRespInline text={props.children} url={props.value.href} external={true} />,
    internalLink: (props: any) => {
      const parentLink = props.value && props.value.parent ? props.value.parent.slug.current : null;
      const route =
        parentLink && props.value.slug ? `/${parentLink}/${props.value.slug.current}` : `/${props.value.slug.current}`;
      return <LinkMediumRespInline text={props.children} url={route} external={false} />;
    },
    asset: (props: any) => <LinkMediumRespInline text={props.children} url={props.value.fileURL} external={true} />,
    strong: (props: any) => <TxtMediumBoldDarkResp>{props.children}</TxtMediumBoldDarkResp>,
  },
  list: {
    bullet: ({ children }) => (
      <SmallWidthSmallTop>
        <UnorderedList>{children}</UnorderedList>
      </SmallWidthSmallTop>
    ),
    number: ({ children }) => (
      <SmallWidthSmallTop>
        <NumberedList>{children}</NumberedList>
      </SmallWidthSmallTop>
    ),
  },
  listItem: {
    number: ({ children }) => <StyledListItem>{children}</StyledListItem>,
    bullet: ({ children }) => <StyledListItem>{children}</StyledListItem>,
  },
};
