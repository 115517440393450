import React from 'react';
import styled from 'styled-components';
import { DeviationMessage } from 'types/DeviationTypes';
import { GeneralDeviation } from 'components/new-design/deviations/GeneralDeviation';
import { TxtXLargeMediumDarkResp } from 'elements/new-design/Typography';
import { MarginBottom, MarginRight, MarginTop } from 'elements/distance/Margins';
import { FlexRow } from 'elements/containers/Containers';

type DeviationsSectionProps = {
  title: string;
  deviations: DeviationMessage[];
  icon?: JSX.Element;
};

const DeviationsList = styled.ul`
  list-style: none;
`;

export const DeviationsSection = ({ title, deviations, icon }: DeviationsSectionProps): JSX.Element => (
  <li>
    <MarginTop margin={5.6}>
      <MarginBottom margin={3.2}>
        <FlexRow>
          {icon ? <MarginRight margin={0.8}>{icon}</MarginRight> : null}

          <h2>
            <TxtXLargeMediumDarkResp>{title}</TxtXLargeMediumDarkResp>
          </h2>
        </FlexRow>
      </MarginBottom>

      <DeviationsList>
        {deviations.map((dev: DeviationMessage, idx: number) => (
          <li key={idx}>
            <GeneralDeviation deviation={dev} />
          </li>
        ))}
      </DeviationsList>
    </MarginTop>
  </li>
);
